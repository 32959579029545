<template>
  <div class="tw-w-full tw-px-3 tw-pb-2">
    <div class="tw-flex tw-flex-col tw-items-center tw-gap-1">
      <h2
        class="tw-mb-0 tw-font-ff-heading tw-text-3xl tw-font-normal tw-decoration-gray-900"
      >
        Reach Our Team
      </h2>
      <h4 class="tw-mb-0 tw-text-base tw-font-medium tw-decoration-gray-500">
        Any questions regarding AdPuzl, we’ll get back to you within 24 hours.
      </h4>
    </div>
    <form
      method="POST"
      data-netlify="true"
      ref="contactForm"
      name="contact-form"
      class="tw-mt-6 tw-grid tw-grid-cols-1 tw-gap-y-6 sm:tw-grid-cols-2 sm:tw-gap-x-8"
    >
      <input type="hidden" name="form-name" value="contact-form" />
      <div>
        <label
          for="Name"
          class="text-gray-700 tw-mb-1 tw-block tw-text-start tw-font-ff-heading tw-text-sm tw-font-medium"
          >Name</label
        >
        <div class="tw-mt-1.5">
          <el-input
            v-model="formData.name"
            type="text"
            name="Name"
            placeholder="Your Name"
            id="Name"
            autocomplete="name"
            class="tw-block tw-w-full tw-rounded-lg tw-border tw-border-gray-300 tw-text-gray-900 focus:tw-border-0"
          />
        </div>
      </div>
      <div>
        <label
          for="email"
          class="text-gray-700 tw-mb-1 tw-block tw-text-start tw-font-ff-heading tw-text-sm tw-font-medium"
          >Email</label
        >
        <div class="tw-mt-1.5">
          <el-input
            v-model="formData.email"
            type="text"
            name="email"
            id="email"
            autocomplete="email"
            placeholder="Your Email"
            class="tw-block tw-w-full tw-rounded-lg tw-border tw-border-gray-300 tw-text-gray-900 focus:tw-border-0"
          />
        </div>
      </div>
      <div class="sm:tw-col-span-2">
        <div class="tw-flex tw-justify-between">
          <label
            for="message"
            class="text-warm-gray-700 tw-mb-1 tw-block tw-text-start tw-font-ff-heading tw-text-sm tw-font-medium"
            >Message</label
          >
        </div>
        <div class="tw-mt-1.5">
          <el-input
            type="textarea"
            v-model="formData.message"
            id="message"
            name="message"
            placeholder="How can we help you?"
            rows="6"
            class="text-warm-gray-900 tw-block tw-w-full tw-rounded-md tw-border tw-border-gray-300 tw-shadow-sm focus:tw-border-teal-500"
            aria-describedby="message-max"
          />
        </div>
        <div
          class="tw-flex tw-flex-row tw-flex-wrap-reverse tw-items-center tw-gap-3 tw-pt-8 sm:tw-flex-nowrap"
        >
          <div class="tw-w-full sm:tw-w-1/2">
            <el-button
              class="tw-h-12 tw-w-full"
              type="default"
              @click="$store.commit('ui/SET_IS_CONTACT_VISIBLE', false)"
            >
              CANCEL
            </el-button>
          </div>
          <div class="tw-w-full sm:tw-w-1/2">
            <el-button
              type="Primary"
              class="tw-mx-0 tw-h-12 tw-w-full tw-border-none tw-bg-amethyst tw-text-white"
              @click="submitForm"
            >
              SEND MESSAGE
            </el-button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      formData: {
        name: "",
        email: "",
        message: "",
      },
    };
  },
  methods: {
    submitForm(e) {
      e.preventDefault();
      const myForm = this.$refs.contactForm;

      const formData = new FormData(myForm);

      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: new URLSearchParams(formData).toString(),
      })
        .then(() => {
          this.$message({
            dangerouslyUseHTMLString: true,
            message: `<p>Thanks for reaching out!</p>
               <p>One of our colleagues will be in touch very soon! Have a great day!</p>`,
            type: "success",
            duration: 3000,
          });
          this.$store.commit("ui/SET_IS_CONTACT_VISIBLE", false);
        })
        .catch(() => {
          this.$message({
            message:
              "There was an error in submitting the form. Please try again.",
            type: "error",
            duration: 3000,
          });
        });
    },
  },
};
</script>
