import { render, staticRenderFns } from "./ApMenu.vue?vue&type=template&id=2a4a5126&scoped=true&"
import script from "./ApMenu.vue?vue&type=script&lang=js&"
export * from "./ApMenu.vue?vue&type=script&lang=js&"
import style0 from "./ApMenu.vue?vue&type=style&index=0&id=2a4a5126&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a4a5126",
  null
  
)

export default component.exports