import Vue from "vue";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faBullseyeArrow,
  faArrowRight,
  faCheckSquare,
  faEdit,
  faTimes,
  faThumbtack,
  faMapMarkerAlt,
  faUsers,
  faDollarSign,
  faFileInvoiceDollar,
  faCalendarCheck,
  faTransgenderAlt,
  faCalendarTimes,
  faPause,
  faEye,
  faPlay,
  faCopy,
  faPlayCircle,
  faPauseCircle,
  faPaperPlane,
  faInfoCircle,
  faChevronLeft,
  faChevronRight,
  faPlus,
  faBars,
  faLockAlt,
  faCheck,
  faUserCheck,
  faStars,
  faTachometerAltFast,
  faTachometerAlt,
  faUserCog,
  faCreditCard,
  faSignOutAlt,
  faCaretDown,
  faExternalLinkAlt,
  faSync,
  faCreditCardFront,
  faCheckCircle,
  faClipboardListCheck,
  faFileDownload,
  faQuestion,
  faCalendarAlt,
} from "@fortawesome/pro-solid-svg-icons";
import {
  faPencilPaintbrush,
  faUser,
  faSquare,
  faCalendarCheck as farCalendarCheck,
  faEdit as farEdit,
  faMegaphone,
  faDollarSign as farDollarSign,
  faThumbtack as farThumbtack,
  faUserCheck as farUserCheck,
  faCalendarTimes as farCalendarTimes,
  faCalendar,
  faAngleRight,
  faInfoCircle as farInfoCircle,
  faLongArrowRight,
  faEnvelope,
  faUserLock,
  faStars as farStars,
  faCheck as farCheck,
  faChevronRight as farChevronRight,
  faCreditCard as farCreditCard,
} from "@fortawesome/pro-regular-svg-icons";
import {
  faCheck as falCheck,
  faLightbulbOn,
  faFileInvoiceDollar as falFileInvoiceDollar,
  faClipboardListCheck as falClipboardListCheck,
} from "@fortawesome/pro-light-svg-icons";
import {
  faYoutube,
  faFacebook,
  faInstagram,
  faTwitter,
  faFacebookSquare,
  faInstagramSquare,
  faCcAmex,
  faCcMastercard,
  faCcVisa,
} from "@fortawesome/free-brands-svg-icons";
import {
  FontAwesomeIcon,
  FontAwesomeLayers,
} from "@fortawesome/vue-fontawesome";

library.add(
  faBullseyeArrow,
  faPencilPaintbrush,
  faUser,
  faCheck,
  falCheck,
  farUserCheck,
  faArrowRight,
  faCheckSquare,
  faSquare,
  faLightbulbOn,
  faYoutube,
  faEdit,
  faTimes,
  faThumbtack,
  faFacebook,
  faTwitter,
  faInstagram,
  farEdit,
  faMapMarkerAlt,
  faUsers,
  faDollarSign,
  faFileInvoiceDollar,
  faCalendarCheck,
  farCalendarCheck,
  faTransgenderAlt,
  faMegaphone,
  farDollarSign,
  farThumbtack,
  faUserCheck,
  faCalendarTimes,
  farCalendarTimes,
  faCalendar,
  faPause,
  faEye,
  faPlay,
  faCopy,
  faPlayCircle,
  faPauseCircle,
  faPaperPlane,
  faAngleRight,
  faInfoCircle,
  farInfoCircle,
  faFacebookSquare,
  faInstagramSquare,
  faChevronLeft,
  faChevronRight,
  faPlus,
  faBars,
  faLongArrowRight,
  faEnvelope,
  faLockAlt,
  faUserLock,
  faStars,
  farStars,
  faTachometerAltFast,
  faTachometerAlt,
  faUserCog,
  farCreditCard,
  faCreditCard,
  faSignOutAlt,
  farCheck,
  faCaretDown,
  farChevronRight,
  faExternalLinkAlt,
  faSync,
  faCreditCardFront,
  faCcAmex,
  faCcMastercard,
  faCcVisa,
  falFileInvoiceDollar,
  faCheckCircle,
  faClipboardListCheck,
  falClipboardListCheck,
  faFileDownload,
  faQuestion,
  faCalendarAlt
);

Vue.component("FontAwesomeIcon", FontAwesomeIcon);
Vue.component("FontAwesomeLayers", FontAwesomeLayers);
