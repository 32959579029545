<template>
  <div class="spinner">
    <svg
      id="puzzle"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 256 256"
    >
      <path
        :class="['st0', 'piece', { mono: color }]"
        :style="{ fill: color }"
        d="M218 120.5a30 30 0 0 0-30-30 30 30 0 0 0-29.1 37.5H128v31a30 30 0 1 1 0 58.1V256h105.5c12.4 0 22.5-10 22.5-22.5V128h-38.9c.6-2.4.9-5 .9-7.5Z"
        fill="#000"
      />
      <path
        :class="['st1', 'piece', { mono: color }]"
        :style="{ fill: color }"
        d="M135.5 218a30 30 0 1 0-7.5-59v-31H97a30 30 0 1 1-58.1 0H0v105.5C0 245.9 10 256 22.5 256H128v-39c2.4.7 5 1 7.5 1Z"
        fill="#000"
      />
      <path
        :class="['st2', 'piece', { mono: color }]"
        :style="{ fill: color }"
        d="M38 135.5a30 30 0 1 0 59-7.5h31V97.1A30 30 0 0 1 90.5 68 30 30 0 0 1 128 38.9V0H22.5C10.1 0 0 10 0 22.5V128h39c-.7 2.4-1 5-1 7.5Z"
        fill="#000"
      />
      <path
        :class="['st3', 'piece', { mono: color }]"
        :style="{ fill: color }"
        d="M233.5 0H128v38.9A30 30 0 0 0 90.5 68 30 30 0 0 0 128 97.1V128h30.9A30 30 0 0 1 188 90.5a30 30 0 0 1 29.1 37.5H256V22.5C256 10.1 246 0 233.5 0Z"
        fill="#000"
      />
    </svg>
  </div>
</template>

<script>
import gsap from "gsap";
export default {
  props: {
    color: String,
  },
  mounted() {
    this.$nextTick(() => {
      const q = gsap.utils.selector(this.$el);
      const puzzle = q("#puzzle");
      const tl = gsap.timeline({ repeat: -1, repeatRefresh: true });
      tl.to(q(".piece"), {
        scale: 0.7,
        transformOrigin: "50% 50%",
        duration: 0.5,
        ease: "bounce.out",
        stagger: 0.07,
        delay: 0.3,
      });
      tl.to(puzzle, { rotation: "+=45", duration: 0.4, ease: "back.inOut" });
      tl.to(q(".piece"), {
        scale: 1,
        duration: 0.7,
        ease: "expo.inOut",
        stagger: 0.05,
      });
      tl.to(puzzle, {
        rotation: "+=45",
        duration: 0.4,
        ease: "back.inOut",
        delay: 0.3,
      });
    });
  },
};
</script>

<style scoped>
.spinner {
  margin: 1em;
  display: grid;
  place-items: center;
}
#puzzle {
  max-width: 60px;
}
.st0 {
  fill: #34495e;
}
.st1 {
  fill: #1abc9c;
}
.st2 {
  fill: #f1c40f;
}
.st3 {
  fill: #9b59b6;
}
.mono:nth-child(1) {
  filter: brightness(90%);
}
.mono:nth-child(2) {
  filter: brightness(100%);
}
.mono:nth-child(3) {
  filter: brightness(80%);
}
.mono:nth-child(4) {
  filter: brightness(110%);
}
</style>
