<template>
  <section
    v-if="$route.meta.isAdPage"
    class="ap-nav__section ap-nav--builder"
    :class="{ 'ap-nav__header': header }"
  >
    <nav>
      <template v-for="(link, i) in builderItems">
        <router-link
          class="ap-nav__link"
          :key="link.pathName + ' ' + i + ' ' + currentStepIndex"
          :to="{ name: link.pathName }"
          custom
          v-slot="{ navigate, isActive }"
          :disabled="i > currentStepIndex"
        >
          <button @click="navigate" :class="{ 'router-link-active': isActive }">
            <div class="svg-wrapper">
              <font-awesome-icon :icon="link.icon" />
            </div>
            <div>
              <span class="ap-nav__text">{{ link.label }}</span>
              <span v-if="link.selectedGoal" class="ap-nav__subtitle"
                >({{ link.selectedGoal }})</span
              >
            </div>
          </button>
        </router-link>
        <font-awesome-icon
          class="ap-nav__divider"
          :icon="['far', 'chevron-right']"
          :key="'icon-' + link.pathName"
          v-if="header && i < builderItems.length - 1"
        />
      </template>
    </nav>
  </section>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    header: Boolean,
  },
  data: () => ({
    stages: [
      { label: "Goal", pathName: "goal", icon: "bullseye-arrow" },
      { label: "Design", pathName: "ad", icon: ["far", "pencil-paintbrush"] },
      {
        label: "Audience & Budget",
        pathName: "audience",
        icon: ["far", "user"],
      },
      { label: "Confirm", pathName: "booking", icon: ["far", "check"] },
    ],
  }),
  computed: {
    ...mapGetters(["currentStepIndex", "selectedGoal"]),
    builderItems() {
      if (this.selectedGoal) {
        return this.stages.map((stage) => {
          if (stage.label === "Goal") {
            stage.selectedGoal = this.selectedGoal.heading;
          }
          return stage;
        });
      } else {
        return this.stages;
      }
    },
  },
};
</script>

<style scoped>
.ap-nav--builder .svg-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--turquoise);
  color: white;
  border-radius: 50%;
  width: 1.5em;
  height: 1.5em;
  margin-right: 0.5em;
}
.ap-nav__header {
  width: 100%;
  max-width: 50em;
  margin: 0 1em;
}
.ap-nav__header > nav {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
}
.ap-nav__header > * {
  flex: 0 1 auto;
}

.ap-nav__subtitle {
  text-transform: none;
  font-size: 80%;
}

.ap-nav__divider {
  flex: 1 1 auto;
}
.ap-nav--builder .svg-wrapper svg {
  color: white !important;
  margin: 0;
  font-size: 0.8rem;
}
.ap-nav__link.router-link-active .svg-wrapper,
.ap-nav__link:hover:not([disabled]) .svg-wrapper,
.ap-nav__link:focus:not([disabled]) .svg-wrapper {
  background-color: var(--amethyst);
}

@media (max-width: 700px) {
  .ap-nav__header > nav {
    justify-content: center;
    margin: 0;
  }
  .ap-nav__header .ap-nav__link:not(.router-link-active),
  .ap-nav__divider {
    display: none;
  }
  .ap-nav__header .ap-nav__link.router-link-active {
    font-size: 1.2rem;
    font-size: clamp(1.3rem, 5vw, 2rem);
    text-align: center;
  }
  .ap-nav__header .ap-nav__link.router-link-active .ap-nav__text {
    background: none;
    text-transform: capitalize;
  }
}

@media (max-width: 900px) {
  .ap-nav__header .svg-wrapper {
    display: none;
  }
}
</style>
