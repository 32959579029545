import Axios from "axios";
import jwt_decode from "jwt-decode";
import dayjs from "dayjs";

const state = {
  user: null,
  token: localStorage.getItem("user-token") || null,
  serverMessage: null,
  paymentInfo: null,
  enableFacebookLogin: false,
  subscription: null,
};

const mutations = {
  SET_SERVER_MESSAGE(state, message, type = "error") {
    if (message === null) {
      state.serverMessage = null;
    } else {
      state.serverMessage = { message, type };
    }
  },
  SET_USER(state, userData) {
    state.user = userData;
  },
  SET_TOKEN(state, token) {
    state.token = token;
  },
  SET_PAYMENT_INFO(state, paymentData) {
    if (paymentData.length === 0) {
      state.paymentInfo = null;
    } else {
      state.paymentInfo = paymentData[0];
    }
  },
  ENABLE_FACEBOOK_LOGIN(state) {
    state.enableFacebookLogin = true;
  },
  SET_SUBSCRIPTION(state, subscriptionData) {
    if (subscriptionData.length === 0) {
      state.subscription = null;
    } else {
      const activeSubscription = subscriptionData.find(
        (subscription) => subscription.status === "active"
      );
      state.subscription = activeSubscription || null;
    }
  },
};

const actions = {
  async signUpUser({ commit }, formData) {
    return Axios.post("/v1/users", formData).catch((error) => {
      commit("SET_SERVER_MESSAGE", error.response.data.message, "error");
    });
  },
  async completeVerification({ commit }, data) {
    const { uid, email_confirmation_token } = data;
    return Axios.patch(`/v1/users/${uid}`, { email_confirmation_token })
      .then((res) => {
        const { jwt } = res.data;
        localStorage.setItem("user-token", jwt);
        commit("SET_TOKEN", jwt);
        Axios.defaults.headers.common["Authorization"] = `Bearer ${jwt}`;
        return true;
      })
      .catch((error) => {
        commit("SET_SERVER_MESSAGE", error.response.data.message, "error");
      });
  },
  async reconfirmEmail({ commit }, formData) {
    return Axios.post("/v1/email-confirmations", formData).catch((error) => {
      commit("SET_SERVER_MESSAGE", error.response.data.message, "error");
    });
  },
  async logIn({ commit, dispatch }, formData) {
    try {
      const tokenRes = await Axios.post("/v1/access-tokens", formData);
      const { jwt } = tokenRes.data;
      localStorage.setItem("user-token", jwt);
      commit("SET_TOKEN", jwt);
      Axios.defaults.headers.common["Authorization"] = `Bearer ${jwt}`;
      const userRes = await dispatch("getUser");
      return userRes;
    } catch (error) {
      commit("SET_SERVER_MESSAGE", error.response.data.message, "error");
      localStorage.removeItem("user-token");
    }
  },
  async getUser({ commit, state, dispatch, rootState }) {
    try {
      const { uid } = jwt_decode(state.token);
      const getUserInfo = () => Axios.get(`/v1/users/${uid}`);
      const getPaymentInfo = () => Axios.get("/v1/payment-methods");
      const getSubscriptions = () => Axios.get("v1/subscriptions");
      const [userRes, paymentRes, subscriptionRes] = await Promise.all([
        getUserInfo(),
        getPaymentInfo(),
        getSubscriptions(),
      ]);
      const { fb_access_token, ...userData } = userRes.data;
      commit("SET_PAYMENT_INFO", paymentRes.data);
      commit("SET_USER", userData);
      commit("SET_SUBSCRIPTION", subscriptionRes.data);
      if (fb_access_token && !rootState.fb.user.access_token) {
        commit("SET_ACCESS_TOKEN", fb_access_token, { root: true });
      }
      commit("ENABLE_FACEBOOK_LOGIN");
      return userRes;
    } catch (error) {
      dispatch("logOut");
    }
  },
  async checkForUserSubscription({ state, dispatch }) {
    const res = await Axios.get(`/v1/users/${state.user.uid}`);
    const { plan, trial_end } = res.data;
    if (plan || trial_end) {
      dispatch("getUser");
    }
    return { plan, trial_end };
  },
  async resetPassword({ commit }, formData) {
    try {
      const res = await Axios.post("/v1/password-resets", formData);
      return res;
    } catch (error) {
      commit("SET_SERVER_MESSAGE", error.response.data.message, "error");
    }
  },
  async updatePassword({ commit }, email_change_token) {
    const { uid } = jwt_decode(email_change_token);
    const payload = { email_change_token };
    try {
      return await Axios.patch(`/v1/users/${uid}`, payload);
    } catch (error) {
      commit("SET_SERVER_MESSAGE", error.response.data.message, "error");
    }
  },
  async updatePasswordAuth({ commit, state }, formData) {
    const { uid } = state.user;
    try {
      return await Axios.patch(`/v1/users/${uid}`, formData);
    } catch (error) {
      commit("SET_SERVER_MESSAGE", error.response.data.message, "error");
    }
  },
  logOut({ commit }) {
    commit("SET_TOKEN", null);
    commit("SET_USER", null);
    localStorage.removeItem("user-token");
    document.location.href = "/login";
  },
  async setNewPassword({ commit }, formData) {
    const { uid } = formData;
    try {
      return await Axios.patch(`/v1/users/${uid}`, formData);
    } catch (error) {
      commit("SET_SERVER_MESSAGE", error.response.data.message, "error");
    }
  },
  async changeEmail({ commit }, formData) {
    try {
      return await Axios.post("/v1/email-changes", formData);
    } catch (error) {
      commit("SET_SERVER_MESSAGE", error.response.data.message, "error");
    }
  },
  async updateEmail({ commit }, email_change_token) {
    const { uid } = jwt_decode(email_change_token);
    const payload = { email_change_token };
    try {
      return await Axios.patch(`/v1/users/${uid}`, payload);
    } catch (error) {
      commit("SET_SERVER_MESSAGE", error.response.data.message, "error");
    }
  },
  async redeemCode({ commit, dispatch }, payload) {
    try {
      const response = await Axios.post("v1/code-redemptions", payload);
      if (response.data.type === "internal") {
        dispatch("getUser");
      }
      return response;
    } catch (error) {
      commit("SET_SERVER_MESSAGE", error.response.data.message, "error");
      return error;
    }
  },
};

const getters = {
  hasToken: (state) => !!state.token,
  isAuth: (state) => !!state.user,
  trialStartDateDayJs: (state) => {
    if (!state.subscription) return null;
    return dayjs(state.subscription.trial_start * 1000);
  },
  trialEndDateDayJs: (state) => {
    if (!state.subscription) return null;
    return dayjs(state.subscription.trial_end * 1000);
  },
  cardDetails: (state) => {
    if (!state.paymentInfo) return null;
    return {
      ...state.paymentInfo.card,
      name: state.paymentInfo.billing_details.name,
    };
  },
  paymentInfo: (state) => state.paymentInfo,
  isTrialActive: (state, getters) => {
    if (!getters.trialEndDateDayJs) return null;
    return getters.trialEndDateDayJs.isAfter(dayjs());
  },
  isTrialEligible: (state) => {
    if (!state.subscription) return true;
    return state.subscription.status === "trialing";
  },
  isPlanActive: (state) => {
    if (!state.subscription) return false;
    return (
      state.subscription.status === "trialing" ||
      state.subscription.status === "active"
    );
  },
  isPremiumUser: (state) => {
    return !!state.subscription && state.subscription.status === "active";
  },

  discountPlanName: (state) => {
    return (
      (state.subscription &&
        state.subscription.discount &&
        state.subscription.discount.coupon &&
        state.subscription.discount.coupon.name) ||
      null
    );
  },
  currentPlanName: (state) => {
    if (!state.subscription || !state.subscription.plan) return null;
    switch (state.subscription.plan.interval) {
      case "month":
        return "AdPuzl Monthly";
      case "year":
        return "AdPuzl Annual";
      default:
        return null;
    }
  },
};
export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
