import Vue from "vue";
import router from "./router";
import ElementUI from "element-ui";
import "./theme/index.css";
import "element-ui/lib/theme-chalk/display.css";
// import '@fortawesome/fontawesome-pro/css/solid.min.css'
import "./plugins/fontawesome";
import "./css/main.css";
// import 'fine-uploader/s3.fine-uploader/fine-uploader-new.css'
import locale from "element-ui/lib/locale/lang/en";
import Axios from "axios";
import store from "./store/store";
import App from "./App.vue";
import Hotjar from "vue-hotjar";
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
import ApSpinner from "./components/ApSpinner.vue";

Axios.defaults.baseURL = process.env.VUE_APP_ADPUZL_API_HOST;

Vue.prototype.$axios = Axios;

// Import the Auth0 configuration

const token = localStorage.getItem("user-token");
if (token) {
  Axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
}

Axios.interceptors.response.use(null, (err) => {
  if (err.response.status === 401 || err.response.status === 403) {
    // if unauthorized, logout the user
    store.dispatch("auth/logOut");
  }
  throw err;
});

// Import the plugin here
// import { Auth0Plugin } from "./auth";

// Install the authentication plugin here
// Vue.use(Auth0Plugin, {
//   domain,
//   clientId,
//   onRedirectCallback: appState => {
//     router.push(
//       appState && appState.targetUrl
//         ? appState.targetUrl
//         : window.location.pathname
//     );
//   }
// });

Sentry.init({
  Vue,
  dsn: "https://7b9142957d1641c696be5c176df26a3d@o539098.ingest.sentry.io/5657236",
  integrations: [new Integrations.BrowserTracing()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
  environment: process.env.NODE_ENV,
});

Vue.use(ElementUI, { locale });

if (process.env.NODE_ENV == "production") {
  Vue.use(Hotjar, {
    id: "2261370", // Hotjar Site ID
    isProduction: true,
  });
}

Vue.component("ApSpinner", ApSpinner);

Vue.mixin({
  methods: {
    toCurrency: (value) => {
      if (typeof value !== "number") {
        return value;
      }
      var formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
      });
      return formatter.format(value);
    },
  },
});

new Vue({
  el: "#app",
  router,
  store,
  render: (h) => h(App),
});
