<template>
  <nav class="ap-nav" tabindex="-1">
    <el-button
      class="ap-nav__close-btn"
      type="text"
      @click="$emit('close-menu')"
    >
      <font-awesome-icon icon="times" />
    </el-button>
    <a
      class="tw-no-underline hover:tw-no-underline"
      href="/goal"
      v-if="!$route.meta.isAdPage"
    >
      <el-button class="ap-nav__new-ad" type="info">Create a new Ad</el-button>
    </a>
    <ApStages class="ap-nav__stages" />
    <section class="ap-nav__section ap-nav--account-pages">
      <router-link
        class="ap-nav__link"
        v-for="link in menuItems.account"
        :key="link.pathName"
        :to="{ name: link.pathName }"
        custom
        v-slot="{ navigate, isExactActive }"
      >
        <button
          @click="navigate"
          :class="{ 'router-link-active': isExactActive }"
        >
          <font-awesome-icon :icon="link.icon" />
          <span class="ap-nav__text">{{ link.label }}</span>
        </button>
      </router-link>
    </section>
    <section class="ap-nav__section ap-nav--misc">
      <a
        class="ap-nav__link"
        v-for="link in menuItems.learning"
        :key="link.label"
        :href="link.href"
        :target="link.href ? '_blank' : ''"
        :rel="link.href ? 'noopener noreferrer nofollow' : ''"
      >
        <span class="ap-nav__text">{{ link.label }}</span>
        <!-- <font-awesome-icon
          class="external-link-icon"
          v-if="link.external"
          icon="external-link-alt"
        /> -->
      </a>
    </section>
    <section class="ap-nav__section ap-nav--misc">
      <a
        class="ap-nav__link"
        v-for="link in menuItems.misc"
        :key="link.label"
        :href="link.href"
        :target="link.href ? '_blank' : ''"
        :rel="link.href ? 'noopener noreferrer nofollow' : ''"
      >
        <span class="ap-nav__text">{{ link.label }}</span>
      </a>
      <a
        class="ap-nav__link"
        key="Contact Us"
        href="javascript:;"
        rel="noopener noreferrer nofollow"
        @click="$store.commit('ui/SET_IS_CONTACT_VISIBLE', true)"
      >
        <span class="ap-nav__text">Contact Us</span>
      </a>
    </section>
    <section class="ap-nav__section ap-nav--footer" v-if="isAuth">
      <button class="ap-nav__link" @click="logOut">
        <font-awesome-icon icon="sign-out-alt" />
        <span class="ap-nav__text">Logout</span>
      </button>
    </section>
  </nav>
</template>

<script>
import ApStages from "../components/ApStages.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  components: { ApStages },
  data: () => ({
    menuItems: {
      builder: [
        { label: "Goal", pathName: "goal", icon: "bullseye-arrow" },
        { label: "Design", pathName: "ad", icon: ["far", "pencil-paintbrush"] },
        {
          label: "Audience & Budget",
          pathName: "audience",
          icon: ["far", "user"],
        },
        { label: "Confirm", pathName: "booking", icon: ["far", "check"] },
      ],
      account: [
        {
          label: "Dashboard",
          pathName: "my-ads",
          icon: "fa-tachometer-alt-fast",
        },
        { label: "Account", pathName: "my-account", icon: "user-cog" },
        // {
        //   label: "Subscription & Billing",
        //   pathName: "audience",
        //   icon: "credit-card",
        // },
      ],
      learning: [
        {
          label: "AdPuzl Blog",
          href: "https://adpuzl.com/blog/",
          external: true,
        },
        {
          label: "Facebook Group",
          href: "https://www.facebook.com/groups/socialadsforbeginners",
          external: true,
        },
      ],
      misc: [
        {
          label: "Privacy Policy",
          href: "https://adpuzl.com/privacy-policy/",
          external: true,
        },
        // {
        //   label: "Contact Us",
        //   href: "javascript:;",
        //   external: true,
        // },
      ],
    },
  }),
  methods: {
    ...mapActions("auth", ["logOut"]),
  },
  computed: {
    ...mapGetters("auth", ["isAuth"]),
  },
};
</script>

<style scoped>
.el-button {
  text-transform: uppercase;
  margin: 0;
}
.ap-nav__stages {
  display: none;
}
.external-link-icon {
  margin-left: 0.15em;
  transform: scale(60%) translateY(0.1em);
}

@media (max-width: 700px) {
  .ap-nav__stages {
    display: block;
  }
}
</style>
