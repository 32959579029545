<template>
  <div class="bottom-toolbar">
    <div class="container bottom-toolbar__buttons">
      <nav aria-label="toolbar">
        <el-button
          type="primary"
          @click="$emit('prev-step', -1)"
          :disabled="$store.getters.ad_mode === 'edit' && $route.path === '/ad'"
        >
          BACK
        </el-button>
        <el-button
          type="info"
          @click="$emit('next-step')"
          @click.shift="$emit('change-step', 1, true)"
          v-if="$route.name !== 'booking'"
        >
          {{ $route.path === "/play" ? "SIGN UP" : "NEXT" }}
        </el-button>
        <el-button
          type="info"
          @click="$emit('book-now')"
          :loading="isAdBooking"
          v-else
        >
          BOOK AD NOW
        </el-button>
      </nav>
      <div class="help hidden-sm-and-up">
        <el-button
          type="primary"
          @click="SET_PREVIEW_VISIBILITY(true)"
          v-if="$route.meta.preview"
        >
          <font-awesome-icon icon="eye" />
        </el-button>
        <el-button
          class="tw-ml-auto"
          type="primary"
          @click="SET_HELP_VISIBILITY(true)"
          v-if="$route.meta.help"
        >
          <font-awesome-icon icon="question" />
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
export default {
  methods: {
    ...mapMutations("ui", ["SET_HELP_VISIBILITY", "SET_PREVIEW_VISIBILITY"]),
  },
  computed: {
    ...mapState("ui", {
      isAdBooking: (state) => state.isAdBooking,
    }),
  },
};
</script>

<style scoped>
.bottom-toolbar {
  background: white;
  padding: 0.75em 0;
  box-shadow: 0 0 5px rgba(31, 31, 31, 0.25);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: env(safe-area-inset-bottom);
  width: 100%;
  z-index: 999;
}

.bottom-toolbar nav {
  z-index: 2;
}
.bottom-toolbar__buttons {
  display: flex;
  justify-content: center;
}
nav .el-button {
  padding: 0.6em 2em;
}
.help {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  padding: 0 0.5em;
  width: 100%;
  z-index: 1;
}

.help .el-button {
  flex: 0 0 2.5em;
  width: 2.5em;
  height: 2.5em;
  border-radius: 50%;
  padding: 0;
}
</style>
