import Vue from "vue";
import Vuex from "vuex";

import adBuilder from "./modules/adBuilder";
import ad from "./modules/ad";
import fb from "./modules/fb";
import adpuzl from "./modules/adpuzl";
import auth from "./modules/auth";
import ui from "./modules/ui";
// import * as actions from './actions';

Vue.use(Vuex);

export default new Vuex.Store({
  // actions,
  modules: {
    adBuilder,
    ad,
    fb,
    adpuzl,
    auth,
    ui,
  },
});
