<template>
  <div :style="cssProps">
    <div class="slot">
      <slot></slot>
    </div>
    <button class="dismiss-btn" v-if="dismissable" @click="$emit('dismiss')">
      <font-awesome-icon icon="fa-times" />
    </button>
  </div>
</template>

<script>
export default {
  props: {
    dismissable: Boolean,
    bgColor: String,
    fontColor: String,
    btnHoverColor: String,
  },
  computed: {
    cssProps() {
      return {
        "--font-color": this.fontColor,
        "--bg-color": this.bgColor,
        "--btn-hover-color": this.btnHoverColor,
      };
    },
  },
};
</script>

<style scoped>
div {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0.625rem 0;
  color: var(--font-color);
  background-color: var(--bg-color, var(--sun-flower));
}

.slot {
  padding: 0 3rem;
}

.dismiss-btn {
  position: absolute;
  right: 1rem;
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: var(--font-color);
}

.dismiss-btn:hover {
  color: var(--btn-hover-color, var(--turquoise));
}
</style>
