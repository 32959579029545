import dayjs from "dayjs";
import { debounce } from "lodash";

const calculateSuggestedDailyBudget = (mau) => {
  if (mau <= 10000) {
    return 5;
  } else if (mau <= 50000) {
    return 10;
  } else if (mau <= 100000) {
    return 15;
  } else if (mau <= 250000) {
    return 20;
  } else if (mau <= 500000) {
    return 25;
  } else if (mau <= 2000000) {
    return 50;
  } else if (mau <= 5000000) {
    return 100;
  } else if (mau > 5000000) {
    return 200;
  } else {
    return 5;
  }
};

const state = {
  adCreative: {
    id: null,
    object_story_spec: null,
    html: null,
    isLoading: false,
  },
  adAccount: null,
  fbPage: null,
  fbPageIndex: null,
  fbPagePicture: null,
  selectedInstagramPage: null,
  adCampaign: {
    id: null,
  },
  adSet: {
    id: null,
    // startDateTime: new Date(),
    startDateTime: null,
    endDateTime: null,
    campaignKeepRunning: true,
    demographics: [],
    detailedTargetingItems: [],
    geo_locations: [],
    age_min: 18,
    age_max: 65,
    billing_event: "IMPRESSIONS",
    optimization_goal: null,
    dailyAdBudget: 10,
    adPlacements: ["facebook_m", "facebook_d", "instagram"],
    adPlacementNames: [],
    genders: [],
    specialAdCategory: "NONE",
    dirty: false,
  },
  leadGenForm: null,
  ad: null,
  business: null,
  targetingBrowseData: [],
  deliveryEstimate: [],
  instagramPages: [],
  myfb: {
    businesses: null,
    pages: null,
    adaccounts: null,
    adAccountModel: null,
    pageModel: null,
    instagramPageModel: null,
  },
  funding_source: null,
  funding_source_details: null,
  loadingFundingSource: false,
  fbAccountStatus: {
    1: "ACTIVE",
    2: "DISABLED",
    3: "UNSETTLED",
    7: "PENDING_RISK_REVIEW",
    8: "PENDING_SETTLEMENT",
    9: "IN_GRACE_PERIOD",
    100: "PENDING_CLOSURE",
    101: "CLOSED",
    201: "ANY_ACTIVE",
    202: "ANY_CLOSED",
  },
};

const mutations = {
  UPDATE_AD(state, ad) {
    state.ad = ad;
  },
  UPDATE_AD_CREATIVE() {
    // state.adCreative.object_story_spec =
  },
  UPDATE_AD_PREVIEW_HTML(state, html) {
    state.adCreative.html = html;
  },
  UPDATE_PREVIEW_STATUS(state, status) {
    state.adCreative.isLoading = status;
  },

  UPDATE_AD_ACCOUNT(state, adAccount) {
    state.adAccount = adAccount;
    state.myfb.adAccountModel = JSON.stringify(adAccount);
  },
  UPDATE_FB_PAGE(state, { fbPage, fbPageIndex }) {
    state.fbPage = fbPage;
    state.fbPageIndex = fbPageIndex;
  },
  UPDATE_AD_CREATIVE_ID(state, id) {
    state.adCreative.id = id;
  },
  UPDATE_TARGETING_BROWSE_DATA(state, data) {
    state.targetingBrowseData = data;
  },
  LOAD_AD_CAMPAIGN(state, data) {
    state.adCampaign.id = data.id;
  },
  LOAD_AUDIENCE_INFO(state, data) {
    state.adSet.detailedTargetingItems = []; // reset detailed targeting items

    if ("ad_placement" in data) {
      state.adSet.adPlacements = data.ad_placement;
    }

    if ("adPlacementNames" in data) {
      state.adSet.adPlacementNames = data.adPlacementNames;
    }

    if ("selectedAgesFrom" in data) {
      state.adSet.age_min = data.selectedAgesFrom;
    }

    if ("selectedAgesTo" in data) {
      state.adSet.age_max = data.selectedAgesTo;
    }

    if ("genders" in data) {
      state.adSet.genders = data.genders;
    }

    if ("geo_locations_meta" in data) {
      //   {
      //     "countries": [
      //         "GB"
      //     ],
      //     "cities": [
      //         {
      //             "country": "CA",
      //             "distance_unit": "mile",
      //             "key": "297116",
      //             "name": "Waterloo",
      //             "region": "Ontario",
      //             "region_id": "533"
      //         }
      //     ],
      //     "regions": [
      //         {
      //             "key": "535",
      //             "name": "Quebec",
      //             "country": "CA"
      //         }
      //     ],
      //     "location_types": [
      //         "home"
      //     ]
      // }

      let geo_locations = [];
      if ("countries" in data.geo_locations_meta) {
        for (const value of Object.entries(data.geo_locations_meta.countries)) {
          geo_locations.push(JSON.stringify(value));
        }
      }

      if ("cities" in data.geo_locations_meta) {
        for (const value of Object.entries(data.geo_locations_meta.cities)) {
          geo_locations.push(JSON.stringify(value));
        }
      }

      if ("regions" in data.geo_locations_meta) {
        for (const [, value] of Object.entries(
          data.geo_locations_meta.regions
        )) {
          geo_locations.push(JSON.stringify(value));
        }
      }

      state.adSet.geo_locations = geo_locations;
    }

    // state.adSet.demographics = data.demographics.selected_items
    // state.adSet.geo_locations = data.locations.selected_items

    state.adSet.campaignKeepRunning = false;

    state.adSet.startDateTime = new Date(Date.parse(data.start_time));
    if ("end_time" in data && data.end_time) {
      state.adSet.endDateTime = new Date(Date.parse(data.end_time));
    } else {
      state.adSet.campaignKeepRunning = true;
      state.adSet.endDateTime = null;
    }
  },
  UPDATE_AUDIENCE(state, data) {
    state.adSet.adPlacements = data.form.ad_placement;
    state.adSet.adPlacementNames = data.form.adPlacementNames;
    state.adSet.demographics = data.form.demographics.selected_items;
    state.adSet.geo_locations = data.form.locations.selected_items;
    state.adSet.campaignKeepRunning = data.form.campaignKeepRunning;
    state.adSet.startDateTime = data.form.startDateTime;
    state.adSet.endDateTime = data.form.endDateTime;
    state.adSet.age_min = data.form.ages.selectedAgesFrom;
    state.adSet.age_max = data.form.ages.selectedAgesTo;

    state.adSet.dailyAdBudget = data.form.dailyAdBudget;

    if (data.form.gender == "Male") {
      state.adSet.genders = [1];
    } else if (data.form.gender == "Female") {
      state.adSet.genders = [2];
    } else {
      state.adSet.genders = [1, 2];
    }
    state.adSet.specialAdCategory = data.form.specialAdCategory;
    state.adSet.dirty = true;
  },
  LOAD_AD_SET(state, adSet) {
    state.adSet.id = adSet.id;
  },
  LOAD_DELIVERY_ESTIMATE(state, data) {
    state.deliveryEstimate = data;
  },
  LOAD_AD_SET_DAILY_BUDGET(state, data) {
    state.adSet.dailyAdBudget = data;
  },
  ADD_DETAILED_TARGETING_ITEM(state, targetingItems) {
    state.adSet.detailedTargetingItems = targetingItems;
  },
  RESET_DETAILED_TARGETING_ITEM(state) {
    state.adSet.detailedTargetingItems = [];
  },
  UPDATE_GENDERS(state, data) {
    if (data == "Male") {
      state.adSet.genders = [1];
    } else if (data == "Female") {
      state.adSet.genders = [2];
    } else {
      state.adSet.genders = [1, 2];
    }
  },
  UPDATE_AGES(state, data) {
    state.adSet.age_min = data.min;
    state.adSet.age_max = data.max;
  },
  UPDATE_DAILY_BUDGET(state, data) {
    state.adSet.dailyAdBudget = Number(data);
  },
  UPDATE_CAMPAIGN_DATES(state, data) {
    state.adSet.campaignKeepRunning = data.campaignKeepRunning;
    state.adSet.startDateTime = data.startDateTime;
    state.adSet.endDateTime = data.endDateTime;
  },
  // 'UPDATE_AUDIENCE_FORM' (state, form) {
  // },
  UPDATE_INSTAGRAM_PAGE(state, data) {
    state.selectedInstagramPage = data;
  },
  UPDATE_INSTAGRAM_PAGES(state, data) {
    state.instagramPages = data;
  },
  UPDATE_OPTIMIZATION_GOAL(state, data) {
    state.adSet.optimization_goal = data.optimization_goal;
    state.adSet.billing_event = data.billing_event;
  },
  UPDATE_BUSINESS(state, data) {
    state.business = data;
  },
  UPDATE_FB_PAGE_PICTURE(state, data) {
    state.fbPagePicture = data;
  },
  UPDATE_MY_FB(state, myfb) {
    const { adaccounts } = myfb;
    const adAccountWithStatusLabel = adaccounts.map((account) => {
      account.account_status_label =
        state.fbAccountStatus[account.account_status];
      return account;
    });
    state.myfb = { ...myfb, adaccounts: adAccountWithStatusLabel };
  },
  UPDATE_FB_FUNDING_INFO: (state, data) => {
    state.funding_source = data.funding_source;
    state.funding_source_details = data.funding_source_details;
  },
  LOADING_FUNDING_SOURCE: (state, success) => {
    state.loadingFundingSource = success;
  },
  LOAD_AD_CAMPAIGN_ID: (state, data) => {
    state.adCampaign.id = data.id;
  },
  LOAD_AD: (state, data) => {
    state.ad = data.ad;
  },
  UPDATE_LEAD_GEN_FORM: (state, formId) => {
    state.leadGenForm = formId;
  },
  RESET_CAMPAIGN_AND_AD_SET_IDS: (state) => {
    state.adSet.id = null;
    state.adCampaign.id = null;
  },
};

const actions = {
  getFundingSourceDetails: ({ commit, rootGetters }) => {
    commit("LOADING_FUNDING_SOURCE", true);

    setTimeout(() => {
      commit("LOADING_FUNDING_SOURCE", false);
    }, 2000);

    rootGetters.fb.api(
      `/${rootGetters.adAccount.id}?fields=name,funding_source,funding_source_details`,
      "GET",
      rootGetters.apiTokenParam,
      (response) => {
        commit("UPDATE_FB_FUNDING_INFO", response);
      }
    );
  },

  changeAdAccount: ({ commit, rootGetters }, adAccount) => {
    if (adAccount?.id) {
      rootGetters.fb.api(
        `/${adAccount.id}/instagram_accounts?fields=username,profile_pic`,
        "GET",
        rootGetters.apiTokenParam,
        (response) => {
          // console.log("Instagram accounts", response)
          commit("UPDATE_INSTAGRAM_PAGES", response.data);
        }
      );

      rootGetters.fb.api(
        `/${adAccount.id}?fields=name,funding_source,funding_source_details`,
        "GET",
        rootGetters.apiTokenParam,
        (response) => {
          commit("UPDATE_FB_FUNDING_INFO", response);
        }
      );
    }

    commit("UPDATE_AD_ACCOUNT", adAccount);
  },

  changePageByIndex: ({ commit, rootGetters }, fbPageIndex) => {
    let fbPage = rootGetters.myfb.pages[fbPageIndex];
    rootGetters.fb.api(
      `/${fbPage.id}/picture?fields=url&redirect=0`,
      "GET",
      rootGetters.apiTokenParam,
      (response) => {
        commit("UPDATE_FB_PAGE_PICTURE", response.data.url);
      }
    );
    commit("UPDATE_FB_PAGE", { fbPage, fbPageIndex });
  },

  changePage: ({ commit, rootGetters }, fbPage) => {
    if (fbPage?.id) {
      rootGetters.fb.api(
        `/${fbPage.id}/picture?fields=url&redirect=0`,
        "GET",
        rootGetters.apiTokenParam,
        (response) => {
          commit("UPDATE_FB_PAGE_PICTURE", response.data.url);
        }
      );

      let page_access_token = fbPage.access_token;

      rootGetters.fb.api(
        `/${fbPage.id}/instagram_accounts?fields=id,username,profile_pic&access_token=${page_access_token}`,
        "GET"
      );
    }

    commit("UPDATE_FB_PAGE", { fbPage, fbPageIndex: 0 });
  },
  LOAD_CAMPAIGN_AND_SET_ID: ({ commit, state }, data) => {
    return new Promise((resolve) => {
      let adaccount = state.myfb.adaccounts.find((el) => {
        return el.account_id == data.ad.ad_account_id;
      });
      commit("UPDATE_AD_ACCOUNT", adaccount);
      commit("LOAD_AD", {
        ad: data.ad,
      });

      commit("UPDATE_FB_FUNDING_INFO", {
        funding_source: null,
        funding_source_details: null,
      });

      let fbPageIndex = state.myfb.pages.findIndex((el) => {
        return el.id == data.ad.page_id;
      });
      commit("UPDATE_FB_PAGE", {
        fbPage: state.myfb.pages[fbPageIndex],
        fbPageIndex: fbPageIndex,
        ad: data.ad,
      });

      commit("LOAD_AD_SET", {
        id: data.ad_set_id,
      });
      commit("LOAD_AD_CAMPAIGN_ID", {
        id: data.campaign_id,
      });

      resolve();
    });
  },

  LOAD_AD_CAMPAIGN: ({ commit, state, dispatch }, campaign) => {
    if (!state.adCampaign.id) {
      commit("LOAD_AD_CAMPAIGN", campaign);
      dispatch("remoteCreateAdSet");
    } else {
      commit("LOAD_AD_CAMPAIGN", campaign);
      dispatch("remoteUpdateAdSet");
    }
  },
  LOAD_AD_SET: ({ commit }, adSet) => {
    commit("LOAD_AD_SET", adSet);
  },
  LOAD_AUDIENCE_INFO: ({ commit }, data) => {
    commit("LOAD_AUDIENCE_INFO", data);
  },
  setTargetingItems: ({ commit }, data) => {
    const targetingItems = data.map((demo) => {
      if (typeof demo === "string") {
        const targetingItem = JSON.parse(demo);
        return {
          id: targetingItem.id,
          type: targetingItem.type,
          label: targetingItem.name,
          path: targetingItem.path.slice(0, -1).join(" > "),
        };
      } else {
        return demo;
      }
    });
    commit("ADD_DETAILED_TARGETING_ITEM", targetingItems);
  },
  UPDATE_DAILY_BUDGET: ({ commit }, data) => {
    commit("UPDATE_DAILY_BUDGET", data);
  },
  UPDATE_OPTIMIZATION_GOAL: ({ commit }, data) => {
    commit("UPDATE_OPTIMIZATION_GOAL", data);
  },
  UPDATE_AD: ({ commit }, data) => {
    commit("UPDATE_AD", data);
  },
  UPDATE_AUDIENCE: ({ commit, dispatch }, data) => {
    commit("UPDATE_AUDIENCE", data);
    dispatch("debouncedRemoteUpdateCampaign");
  },

  debouncedRemoteUpdateCampaign: debounce(({ dispatch, state }) => {
    if (
      state.adSet.geo_locations.length == 0 ||
      state.adSet.startDateTime == null
    ) {
      return false;
    } else if (!state.adCampaign.id) {
      dispatch("remoteCreateCampaign");
    } else {
      dispatch("remoteUpdateCampaign");
    }
  }, 1000),

  remoteCreateCampaign: ({ state, dispatch, getters, rootGetters, commit }) => {
    rootGetters.fb.api(
      `/${state.adAccount.id}/campaigns`,
      "POST",
      {
        name: getters.adName,
        // objective: "LINK_CLICKS",
        objective: rootGetters.isLeadGen ? "LEAD_GENERATION" : "LINK_CLICKS",
        // status: "PAUSED",
        status: "ACTIVE",
        spend_cap: 10000,
        special_ad_categories: state.adSet.specialAdCategory,
        ...rootGetters.apiTokenParam,
      },
      (response) => {
        if (response.error) {
          commit(
            "ui/SET_CRITICAL_MODAL_DATA",
            {
              body: response.error.error_user_msg,
              title: response.error.error_user_title,
            },
            { root: true }
          );
        } else {
          dispatch("LOAD_AD_CAMPAIGN", response);
        }
      }
    );
  },
  remoteUpdateCampaign: ({ state, dispatch }) => {
    if (state.adSet.id) {
      dispatch("remoteUpdateAdSet");
    } else {
      dispatch("remoteCreateAdSet");
    }
  },
  resetCampaignAndAdSetIds: ({ commit, dispatch }) => {
    commit("RESET_CAMPAIGN_AND_AD_SET_IDS");
    dispatch("debouncedRemoteUpdateCampaign");
  },
};

const getters = {
  leadGenFormName: (state) => {
    return state.leadGenForm && state.leadGenForm.name.replace(/\+/g, " ");
  },
  ad: (state) => {
    return state.ad;
  },
  adName: (state, getters, rootState, rootGetters) => {
    if (
      rootGetters.adSet.startDateTime &&
      rootGetters.fbPage.name &&
      rootGetters.goalName
    ) {
      return (
        rootGetters.fbPage.name +
        " | " +
        rootGetters.goalName +
        " | " +
        dayjs(rootGetters.adSet.startDateTime).format("YYYY-MM-DD")
      );
    }

    return null;
  },
  adAccount: (state) => {
    return state.adAccount;
  },
  adCreative: (state) => {
    return state.adCreative;
  },
  adCampaign: (state) => {
    return state.adCampaign;
  },
  adSet: (state) => {
    return state.adSet;
  },
  adPreviewIsLoading: (state) => {
    return state.adCreative.isLoading;
  },
  deliveryEstimate: (state) => {
    return state.deliveryEstimate;
  },
  deliveryEstimateMAU: (state) => {
    return (
      state.deliveryEstimate[0] &&
      state.deliveryEstimate[0].estimate_mau_upper_bound
    );
  },
  deliveryEstimateLowerMAU: (state) => {
    return (
      state.deliveryEstimate[0] &&
      state.deliveryEstimate[0].estimate_mau_lower_bound
    );
  },
  recommendedDailyBudget: (state, getters) => {
    if (state.deliveryEstimate.length > 0) {
      const upper = calculateSuggestedDailyBudget(getters.deliveryEstimateMAU);
      const lower = calculateSuggestedDailyBudget(
        getters.deliveryEstimateLowerMAU
      );

      return { lower, upper };
    }
    return null;
  },
  page: (state) => {
    return state.ad ? state.ad.fbPage : null;
  },
  pagePicture: (state) => {
    return state.fbPagePicture;
  },
  objectStorySpec: (state, getters, rootState) => {
    let mockup = getters.mockup;
    let call_to_action_value = {};

    if (state.leadGenForm) {
      call_to_action_value.lead_gen_form_id = state.leadGenForm.formID;
    } else {
      call_to_action_value.link = mockup.url;
    }

    if (getters.adMediaIsVideo) {
      // call_to_action_value.link_caption = "adpuzl.com/link_caption";

      return {
        page_id: state.fbPage ? state.fbPage.id : null,
        video_data: {
          video_id: rootState.adBuilder.adCopy.videoId,
          image_url: rootState.adBuilder.adCopy.thumbnail_url,
          message: mockup.description,
          title: mockup.title,
          call_to_action: {
            type: mockup.cta || "LEARN_MORE",
            value: call_to_action_value,
          },
          link_description: mockup.secondary_title,
        },
      };
    }

    return {
      page_id: state.fbPage ? state.fbPage.id : null,
      link_data: {
        link: mockup.url,
        message: mockup.description,
        name: mockup.title,
        description: mockup.secondary_title,
        caption: mockup.url,
        picture: mockup.image_url,
        call_to_action: {
          type: mockup.cta || "LEARN_MORE",
          value: call_to_action_value,
        },
      },
    };
  },
  targetingBrowseData: (state) => {
    // console.log("Lodash group by result", state.targetingBrowseData.length)
    return state.targetingBrowseData;

    // todo: use cascader lazy loading
    // let cascaderItemObjects = {};
    // _.forEach(state.targetingBrowseData, (x) => {
    //   let current_path = x.path.shift();
    //   if (!_.has(cascaderItemObjects, current_path)) {
    //     cascaderItemObjects[current_path] = {
    //       value: x.id,
    //       label: current_path,
    //       children: [],
    //     };
    //   } else {
    //     cascaderItemObjects[current_path].children.push({
    //       value: x.id,
    //       label: x.name,
    //       children: [],
    //       raw: x,
    //     });
    //   }
    // });

    // let pathObject = _.groupBy(state.targetingBrowseData, x => {
    //   let current_path = x.path[0]
    //   x.path.splice(0, 1)
    //   return current_path
    // })

    // let cascaderItems = Object.keys(pathObject).map(key => {
    //   return {
    //     value: key,
    //     label: key,
    //     children: pathObject[key]
    //   }
    // })

    // return _.toArray(cascaderItemObjects);

    // state.tartetingBrowseData.reduce( (accumulator, currentItem) => {
    //   // 1st level
    //   if (accumulator == null) {
    //     return {

    //     }
    //   }
    // })
  },

  detailedTargetingItems: (state) => {
    return state.adSet.detailedTargetingItems;
  },
  targetingLocations: (state) => {
    return state.adSet.geo_locations.map((x) => {
      return JSON.parse(x);
    });
  },
  adPlacements: (state) => {
    let publisher_platforms = [];

    if (state.adSet.adPlacements.includes("instagram")) {
      publisher_platforms.push("instagram");
    }

    if (
      state.adSet.adPlacements.includes("facebook_m") ||
      state.adSet.adPlacements.includes("facebook_d")
    ) {
      publisher_platforms.push("facebook");
    }

    return publisher_platforms;
  },

  devicePlatforms: (state) => {
    let device_platforms = [];
    if (!state.adSet.adPlacements) {
      return [];
    }

    if (state.adSet.adPlacements.includes("facebook_m")) {
      device_platforms.push("mobile");
    }

    if (state.adSet.adPlacements.includes("facebook_d")) {
      device_platforms.push("desktop");
    }

    if (device_platforms.length == 0) {
      device_platforms.push("mobile");
    }
    return device_platforms;
  },

  instagramPages: (state) => {
    return state.instagramPages;
  },
  fbPage: (state) => {
    return state.fbPage;
  },
  selectedInstagramPage: (state) => {
    return state.selectedInstagramPage;
  },
  business: (state) => {
    return state.business;
  },
  myfb: (state) => {
    return state.myfb;
  },
  fundingSource: (state) => {
    return state.funding_source;
  },
  fundingSourceDetails: (state) => {
    return state.funding_source_details;
  },
  loadingFundingSource: (state) => {
    return state.loadingFundingSource;
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
