// import stocks from '../../data/stocks';
import Axios from "axios";
import _ from "lodash";

const optimization_goals = {
  NONE: "NONE",
  APP_INSTALLS: "APP_INSTALLS",
  BRAND_AWARENESS: "BRAND_AWARENESS",
  AD_RECALL_LIFT: "AD_RECALL_LIFT",
  CLICKS: "CLICKS",
  ENGAGED_USERS: "ENGAGED_USERS",
  EVENT_RESPONSES: "EVENT_RESPONSES",
  IMPRESSIONS: "IMPRESSIONS",
  LEAD_GENERATION: "LEAD_GENERATION",
  QUALITY_LEAD: "QUALITY_LEAD",
  LINK_CLICKS: "LINK_CLICKS",
  OFFER_CLAIMS: "OFFER_CLAIMS",
  OFFSITE_CONVERSIONS: "OFFSITE_CONVERSIONS",
  PAGE_ENGAGEMENT: "PAGE_ENGAGEMENT",
  PAGE_LIKES: "PAGE_LIKES",
  POST_ENGAGEMENT: "POST_ENGAGEMENT",
  REACH: "REACH",
  SOCIAL_IMPRESSIONS: "SOCIAL_IMPRESSIONS",
  APP_DOWNLOADS: "APP_DOWNLOADS",
  TWO_SECOND_CONTINUOUS_VIDEO_VIEWS: "TWO_SECOND_CONTINUOUS_VIDEO_VIEWS",
  LANDING_PAGE_VIEWS: "LANDING_PAGE_VIEWS",
  VISIT_INSTAGRAM_PROFILE: "VISIT_INSTAGRAM_PROFILE",
  VALUE: "VALUE",
  THRUPLAY: "THRUPLAY",
  REPLIES: "REPLIES",
  DERIVED_EVENTS: "DERIVED_EVENTS",
};

const billing_events = {
  APP_INSTALLS: "APP_INSTALLS",
  CLICKS: "CLICKS",
  IMPRESSIONS: "IMPRESSIONS",
  LINK_CLICKS: "LINK_CLICKS",
  NONE: "NONE",
  OFFER_CLAIMS: "OFFER_CLAIMS",
  PAGE_LIKES: "PAGE_LIKES",
  POST_ENGAGEMENT: "POST_ENGAGEMENT",
  THRUPLAY: "THRUPLAY",
};

const state = {
  // stocks: [],
  currentAdDraftId: "",
  adDrafts: [],
  adFieldNames: {
    image_url: "Ad Image",
    description: "Ad Primary Text",
    secondary_title: "Ad Description",
    url: "Ad URL",
    title: "Ad Headline",
    cta: "Ad Call to Action",
  },
  selectedAdAccount: "",
  selectedGoalIndex: null,
  defaultAdCopy: null,
  adCopy: {
    profile_image_url: require("../../assets/adpuzl-logo-icon.png"),
    header: "AdPuzl",
    subheader: "Created Using AdPuzl",
    url: "",
    title: "",
    secondary_title: "",
    description: "",
    image_url: "",
    cta: null,
    thumbnail_url: null,
    videoId: null,
  },
  adVideo: null,
  fieldsWithErrors: [],
  admockups: [
    {
      profile_image_url: require("../../assets/adpuzl-logo-icon.png"),
      header: "AdPuzl",
      subheader: "Created Using AdPuzl",
      url: "HTTPS://WWW.ADPUZL.COM",
      title: "Hire The World's Best Marketers Instantly",
      secondary_title: "Hire Better Marketing Talent, Faster.",
      description: "Finally, Marketers, You Can Trust.",
      image_url:
        "https://adpuzlpublic.s3.amazonaws.com/adpuzl-ad-creative-image-placeholder.jpg",
      fit: "cover",
      goalName: "Reach",
    },
    {
      profile_image_url: require("../../assets/adpuzl-logo-icon.png"),
      header: "AdPuzl",
      subheader: "Created Using AdPuzl",
      url: "HTTPS://WWW.ADPUZL.COM",
      title: "Give AdPuzl a try",
      secondary_title: "You coul win one million dollars",
      description:
        "Ready To Transform Your Practice? Learn why AdPuzl is trusted by thousands of hearts.",
      image_url:
        "https://adpuzlpublic.s3.amazonaws.com/adpuzl-ad-creative-image-placeholder.jpg",
      fit: "cover",
      goalName: "Engagement",
    },
    {
      profile_image_url: require("../../assets/adpuzl-logo-icon.png"),
      header: "AdPuzl",
      subheader: "Created Using AdPuzl",
      url: "HTTPS://WWW.ADPUZL.COM",
      title: "Hire The World's Best Marketers Instantly",
      secondary_title: "Hire Better Marketing Talent, Faster.",
      description: "Finally, Marketers, You Can Trust.",
      image_url:
        "https://adpuzlpublic.s3.amazonaws.com/adpuzl-ad-creative-image-placeholder.jpg",
      fit: "cover",
      goalName: "Traffic",
    },
    {
      profile_image_url: require("../../assets/adpuzl-logo-icon.png"),
      header: "AdPuzl",
      subheader: "Created Using AdPuzl",
      url: "HTTPS://WWW.ADPUZL.COM",
      title: "Hire The World's Best Marketers Instantly",
      secondary_title: "Hire Better Marketing Talent, Faster.",
      description: "Finally, Marketers, You Can Trust.",
      image_url:
        "https://adpuzlpublic.s3.amazonaws.com/adpuzl-ad-creative-image-placeholder.jpg",
      fit: "cover",
      goalName: "Lead Generation",
    },
  ],
  goals: [
    {
      heading: "Reach",
      sub_heading: "Get your ad in front of as many people as possible",
      description: "",
      img: require("../../assets/puzzlegoals-reach.png"),
      name: "reach",
      is_available: true,
      optimization_goal: optimization_goals.REACH,
      billing_event: billing_events.IMPRESSIONS,
    },
    {
      heading: "Engagement",
      sub_heading:
        "Get your ad in front of people who will like, comment, and share",
      description: "",
      img: require("../../assets/puzzlegoals-engagement.png"),
      name: "engagement",
      is_available: true,
      optimization_goal: optimization_goals.POST_ENGAGEMENT,
      billing_event: billing_events.IMPRESSIONS,
    },
    {
      heading: "Traffic",
      sub_heading:
        "Get your ad in front of the people most likely to click on them",
      description: "",
      img: require("../../assets/puzzlegoals-traffic.png"),
      name: "traffic",
      is_available: true,
      optimization_goal: optimization_goals.LINK_CLICKS,
      billing_event: billing_events.IMPRESSIONS,
    },
    {
      heading: "Lead Gen",
      sub_heading:
        "Get your ad in front of people who are most likely to purchase your product or service",
      description: "",
      img: require("../../assets/puzzlegoals-leadgen-1.png"),
      name: "leadgen",
      is_available: true,
      optimization_goal: optimization_goals.LEAD_GENERATION,
      billing_event: billing_events.IMPRESSIONS,
    },
  ],
};

const mutations = {
  SET_CURRENT_AD_DRAFT_ID(state, data) {
    state.currentAdDraftId = data;
  },
  SET_AD_FROM_AD_DRAFT(state) {
    state.adCopy.cta = state.adDrafts[0].cta;
    state.adCopy.description = state.adDrafts[0].description;
    state.adCopy.image_url = state.adDrafts[0].image_url;
    state.adCopy.secondary_title = state.adDrafts[0].secondary_title;
    state.adCopy.title = state.adDrafts[0].title;
    state.adCopy.url = state.adDrafts[0].url;
  },
  SET_AD_FROM_AD_DRAFT_ID(state, data) {
    state.adCopy.cta = data.cta;
    state.adCopy.description = data.description;
    state.adCopy.image_url = data.image_url;
    state.adCopy.secondary_title = data.secondary_title;
    state.adCopy.title = data.title;
    state.adCopy.url = data.url;
  },
  CHANGE_GOAL(state, goal_index) {
    state.selectedGoalIndex = goal_index;
  },
  SET_GOAL(state, data) {
    state.selectedGoalIndex = data.index;
    state.selectedGoal = data.goal;
  },
  UPDATE_CURRENT_MOCKUP(state, mockup) {
    state.admockups[state.selectedGoalIndex] = mockup;
  },
  UPDATE_AD_FULL_URL(state, url) {
    state.adCopy.url = url;
  },
  UPDATE_CTA(state, cta) {
    state.adCopy.cta = cta;
  },
  INITIALIZE_DEFAULT_AD_COPY(state, adCopy) {
    if (state.defaultAdCopy == null) {
      state.defaultAdCopy = {
        description: adCopy.description,
        image_url: adCopy.image_url,
        secondary_title: adCopy.secondary_title,
        title: adCopy.title,
        url: adCopy.url,
        cta: null,
      };
    }
  },

  UPDATE_FIELDS_WITH_ERRORS(state, fields) {
    state.fieldsWithErrors = fields;
  },

  REMOVE_FIELD_ERROR(state, fieldName) {
    let index = state.fieldsWithErrors.indexOf(fieldName);
    if (index > -1) {
      state.fieldsWithErrors.splice(index, 1);
    }
  },

  LOAD_AD_DATA(state, data) {
    state.adCopy = {
      ...state.adCopy,
      ...data.adCopy,
    };
  },
  SET_AD_CREATIVE_THUMBNAIL(state, data) {
    state.adCopy.thumbnail_url = data.thumbnail_url;
  },
  UPDATE_AD_VIDEO_ID(state, videoId) {
    state.adCopy.videoId = videoId;
  },
  UPDATE_AD_VIDEO_DATA(state, data) {
    state.adVideo = data;
  },
};

const actions = {
  async getAdDraft({ commit, state }) {
    Axios.get("v1/ad-drafts")
      .then((response) => {
        state.adDrafts = response.data;
      })
      .catch((error) => {
        commit(
          "ui/SET_NEW_NOTIFICATION",
          {
            title: error,
            type: "warning",
          },
          { root: true }
        );
      });
  },

  async getAdDraftById({ commit }, draftId) {
    Axios.get(`v1/ad-drafts/${draftId}`)
      .then((response) => {
        const adData = response.data;
        commit("SET_AD_FROM_AD_DRAFT_ID", adData);
      })
      .catch((error) => {
        commit(
          "ui/SET_NEW_NOTIFICATION",
          {
            title: error,
            type: "warning",
          },
          { root: true }
        );
      });
  },

  async createAdDraft({ commit, state }) {
    const payload = {
      description: state.adCopy.description,
      title: state.adCopy.title,
      url: state.adCopy.url,
      secondary_title: state.adCopy.secondary_title,
      image_url: state.adCopy.image_url,
      cta: state.adCopy.cta,
    };
    Axios.post("v1/ad-drafts", payload)
      .then((response) => {
        localStorage.setItem("ad_draft_id", response.data.uid);
        commit("SET_CURRENT_AD_DRAFT_ID", response.data.uid);
      })
      .catch((error) => {
        commit(
          "ui/SET_NEW_NOTIFICATION",
          {
            title: error,
            type: "warning",
          },
          { root: true }
        );
      });
  },

  setGoal: ({ commit, dispatch }, goalData) => {
    commit("SET_GOAL", goalData);
    dispatch("UPDATE_OPTIMIZATION_GOAL", goalData.goal);
  },

  changeGoalByName: ({ commit }, data) => {
    switch (data.name.toLowerCase()) {
      case "reach":
        commit("CHANGE_GOAL", 0);
        break;

      case "engagement":
        commit("CHANGE_GOAL", 1);
        break;

      case "traffic":
        commit("CHANGE_GOAL", 2);
        break;

      case "leadgen":
        commit("CHANGE_GOAL", 3);
        break;

      default:
        commit("CHANGE_GOAL", 1);
        break;
    }
  },

  checkAndUploadAdVideo: ({ getters, commit, dispatch }) => {
    new Promise((resolve) => {
      if (getters.adMediaIsVideo) {
        let thumbnailData = null;
        let adVideo = null;
        dispatch("uploadAdVideo")
          .then((response) => {
            return response;
          })
          .then(async (video) => {
            adVideo = video;
            const syncWait = function wait(ms) {
              return new Promise((resolve) => {
                setTimeout(() => {
                  resolve(ms);
                }, ms);
              });
            };

            let dispatchResult = null;
            while (thumbnailData == null) {
              await syncWait(2000);
              dispatchResult = dispatch("adVideoThumbnail", adVideo.id).then(
                (data) => {
                  thumbnailData = data;
                }
              );
            }
            adVideo = video;
            commit("UPDATE_AD_VIDEO_ID", adVideo.id);
            return dispatchResult;
          })
          .then(() => {
            commit("SET_AD_CREATIVE_THUMBNAIL", {
              thumbnail_url: thumbnailData.uri,
            });
          });
        resolve(true);
      }

      resolve(null);
    });
  },
};

const getters = {
  admockups: (state) => {
    return state.admockups;
  },
  mockup: (state) => {
    return state.adCopy;
  },
  selectedGoalIndex: (state) => {
    return state.selectedGoalIndex;
  },
  selectedGoal: (state) => {
    return state.goals[state.selectedGoalIndex];
  },
  isLeadGen: (state, getters) => {
    return getters.selectedGoal && getters.selectedGoal.name === "leadgen";
  },
  goalName: (state) => {
    if (state.admockups[state.selectedGoalIndex]) {
      return state.admockups[state.selectedGoalIndex].goalName;
    }

    return "Traffic";
  },
  hasCleanField: (state) => {
    var hasClean = false;
    _.forEach(state.defaultAdCopy, (value, key) => {
      if (value == state.adCopy[key]) {
        hasClean = true;
        // console.log('clean field', key)
      }
    });
    return hasClean;
  },
  cleanFields: (state) => {
    let cleanFields = [];
    _.forEach(state.defaultAdCopy, (value, key) => {
      if (value == state.adCopy[key]) {
        cleanFields.push(key);
        // console.log('clean field', key)
      }
    });

    return cleanFields;
  },
  cleanFieldsHtml: (state, getters) => {
    var html = "<ul>";
    _.forEach(getters.cleanFields, (value) => {
      html += "<li>" + state.adFieldNames[value] + "</li>";
    });
    html += "</ul>";
    return html;
  },
  defaultAdCopy: (state) => {
    return state.defaultAdCopy;
  },
  fieldsWithErrors: (state) => {
    return state.fieldsWithErrors;
  },
  ctaLabel: (state, rootGetters) => {
    let result = rootGetters.available_ctas[
      rootGetters.goalName.toLowerCase()
    ].find((cta) => {
      return cta.key == state.adCopy.cta;
    });

    if (result) {
      return result.label;
    }

    return "LEARN MORE";
  },
  adMediaIsVideo: (state, getters) => {
    return ["mp4", "mov", "wmv", "mkv", "avi"].includes(
      getters.adMediaUrlExtension
    );
  },
  adVideoIsPreviewable: (state, getters) => {
    return ["mp4"].includes(getters.adMediaUrlExtension);
  },
  adMediaUrlExtension: (state) => {
    return state.adCopy.image_url
      .split(/[#?]/)[0]
      .split(".")
      .pop()
      .trim()
      .toLowerCase();
  },
  videoSource: (state) => {
    return state.adVideo && state.adVideo.source ? state.adVideo.source : "";
  },
};

// const { getAdBuilderField, updatedBuilderField } = createHelpers({
//   getterType: 'getAdBuilderField',
//   mutationType: 'updatedBuilderField',
// });

export default {
  // namespaced: true,
  state,
  mutations: {
    ...mutations,
    // getAdBuilderField,
  },
  actions,
  getters: {
    ...getters,
    // updatedBuilderField,
  },
};
