// eslint-disable-next-line no-unused-vars
import _ from "lodash";
import Axios from "axios";

const state = {
  myad: null,
  api_base: process.env.VUE_APP_ADPUZL_API_HOST,
  user_email: null,
  payment_methods: [],
  is_tooltip_on: false,
  active_hint_hub: null,
  ad_mode: "create",
  currentStep: null,
  stripeWindow: null,
  planRates: {
    1: { price: 39, per: "month" },
    2: { price: 29.95, per: "month" },
  },
};

const mutations = {
  UPDATE_USER_EMAIL(state, email) {
    state.user_email = email;
  },
  UPDATE_PAYMENT_METHODS(state, data) {
    state.payment_methods = data;
  },
  SWITCH_HINT_HUB(state, hub_name) {
    state.active_hint_hub = hub_name;
  },

  CHANGE_AD_MODE(state, data) {
    state.ad_mode = data.mode;
  },
  SET_CURRENT_STEP(state, data) {
    state.currentStep = data;
  },
  SET_STRIPE_WINDOW(state, window) {
    state.stripeWindow = window;
  },
};

const actions = {
  ADPUZL_CREATE_AD: ({ rootGetters }, remoteAdData) => {
    return new Promise((resolve) => {
      Axios.post("/v1/ads", {
        ...remoteAdData,
        ad_id: remoteAdData.id,
        ad_name: remoteAdData.name,
        ad_budget: rootGetters.adSet.dailyAdBudget,
        objective: rootGetters.goalName,
        // business_id: rootGetters.business.id,
        campaign_id: rootGetters.adCampaign.id,
        status: remoteAdData.status,
        effective_status: remoteAdData.effective_status,
        ad_set_id: remoteAdData.adset_id,
        ad_account_id: rootGetters.adAccount.account_id,
        page_id: rootGetters.fbPage.id,
        start_time: Math.floor(
          rootGetters.adSet.startDateTime.getTime() / 1000
        ),
        end_time: rootGetters.adSet.endDateTime
          ? Math.floor(rootGetters.adSet.endDateTime.getTime() / 1000)
          : null,
      }).then((response) => {
        resolve(response);
      });
    });
  },
  ADPUZL_UPDATE_AD: (remoteAdData) => {
    return new Promise((resolve) => {
      Axios.patch("/v1/ads/" + remoteAdData.id, {
        ...remoteAdData,
        // ad_id: remoteAdData.id,
        // ad_name: remoteAdData.name,
        // ad_budget: rootGetters.adSet.dailyAdBudget,
        // objective: rootGetters.goalName,
        // campaign_id: rootGetters.adCampaign.id,
        status: remoteAdData.status,
        effective_status: remoteAdData.effective_status,
        // ad_set_id: remoteAdData.adset_id,
        // ad_account_id: rootGetters.adAccount.account_id,
        // page_id: rootGetters.fbPage.id,
        // start_time: Math.floor(rootGetters.adSet.startDateTime.getTime() / 1000),
        // end_time: rootGetters.adSet.endDateTime ? Math.floor(rootGetters.adSet.endDateTime.getTime() / 1000) : null
      }).then((response) => {
        resolve(response);
      });
    });
  },

  ADPUZL_REFRESH_AD: (id) => {
    return new Promise((resolve) => {
      Axios.post("remote/refresh-ad", {
        id: id,
      }).then((response) => {
        resolve(response.data);
      });
    });
  },

  REVALIDATE_USER: ({ commit, dispatch }, access_token) => {
    return new Promise((resolve, reject) => {
      Axios.patch("profile/validate", {
        access_token: access_token,
      }).then((response) => {
        // this.userJwt = response.body.jwt
        dispatch("UPDATE_USER_JWT", response.data.jwt);
        dispatch("registerLogin", this.FB);
        commit("UPDATE_USER_EMAIL", response.data.email);
        commit("UPDATE_PAYMENT_METHODS", response.data.payment_methods.data);

        if (response.data.jwt) {
          resolve(response.data.jwt);
        } else {
          reject(response.data);
        }
      });
    });
  },

  CREATE_STRIPE_CHECKOUT_SESSION: (data) => {
    if (data.sessionId) {
      return data.stripe.redirectToCheckout({
        sessionId: data.sessionId,
      });
    }
    return new Promise((resolve, reject) => {
      Axios.post("/remote/create-checkout-session", {
        billingPeriod: data.billingPeriod,
        customerEmail: data.customer ? null : data.email,
        customer: data.customer,
      })
        .then(function (session) {
          if (session.data.url) {
            location.href = session.data.url;
            return;
          }

          // redirectToCheckout is marked as deprecated https://stripe.com/docs/js/deprecated/redirect_to_checkout
          return data.stripe.redirectToCheckout({
            sessionId: session.data.id,
          });
        })
        .then(function (result) {
          // If redirectToCheckout fails due to a browser or network
          // error, you should display the localized error message to your
          // customer using error.message.
          if (result.error) {
            alert(result.error.message);
            reject();
            return;
          }
          resolve();
          return;
        })
        .catch(function () {});
    });
  },

  openStripeCheckoutWindow: ({ state, rootState, commit }, payload) => {
    const width = 1000;
    const height = 655;
    const { screenX, screenY, innerWidth, innerHeight, location } = window;
    const offsetX = (+innerWidth - width) / 2 + screenX;
    const offsetY = (+innerHeight - height) / 2 + screenY;
    let customer = "";
    if (rootState.auth.paymentInfo) {
      customer = rootState.auth.paymentInfo.customer;
    }
    const sessionId = payload.session_id
      ? `&session=${payload.session_id}`
      : "";
    const url = `${location.origin}/payment?billingPeriod=${
      payload.planType
    }&email=${encodeURIComponent(
      rootState.auth.user.email
    )}&customer=${encodeURIComponent(customer)}${sessionId}`;
    const features = `status=no,toolbar=no,location=no,menubar=no,width=${width},height=${height},screenX=${offsetX},screenY=${offsetY}`;
    commit("SET_STRIPE_WINDOW", window.open(url, "stripeWindow", features));
    return new Promise((resolve) => {
      const timer = setInterval(() => {
        if (state.stripeWindow.closed) {
          commit("SET_STRIPE_WINDOW", null);
          clearInterval(timer);
          resolve("window closed");
        }
      }, 500);
    });
  },
};

const getters = {
  user_email: (state) => {
    return state.user_email;
  },
  payment_methods: (state) => {
    return state.payment_methods;
  },
  is_tooltip_on: (state) => {
    return state.is_tooltip_on;
  },

  active_hint_hub: (state) => {
    return state.active_hint_hub;
  },

  ad_mode: (state) => {
    return state.ad_mode;
  },

  currentStepIndex: (state) => {
    switch (state.currentStep) {
      case "/goal":
        return 0;

      case "/ad":
        return 1;

      case "/audience":
        return 2;

      case "/booking":
        return 3;

      default:
        return 0;
    }
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
