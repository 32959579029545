const state = {
  criticalModalData: {},
  isCriticalModalVisible: false,
  newNotification: {},
  isAccountMissingVisible: false,
  isShareLinkVisible: false,
  isContactVisible: false,
  isHelpVisible: false,
  isPreviewVisible: false,
  isAdBooking: false,
  isAdBooked: false,
  isAdBlockBannerOn: false,
  isFetchingNext: false,
  demoChecklist: [
    {
      index: 1,
      name: "Ad Text",
      focus: "ad_header",
      condition: "",
      focusField: "ad_text",
    },
    {
      index: 2,
      name: "Image",
      focus: "ad_image",
      condition: "",
      focusField: "ad_media",
    },
    {
      index: 3,
      name: "URL",
      focus: "ad_url",
      condition: "",
      focusField: "ad_url",
    },
    {
      index: 4,
      name: "Headline",
      focus: "ad_headline",
      condition: "",
      focusField: "ad_headline",
    },
    {
      index: 5,
      name: "Description",
      focus: "ad_description",
      condition: "",
      focusField: "ad_description",
    },
    {
      index: 6,
      name: "Call to Action",
      focus: "ad_cta",
      condition: "",
      focusField: "ad_cta",
    },
  ],
  adPreviewChecklist: [
    {
      index: 2,
      name: "Ad Text",
      focus: "ad_header",
      condition: "",
      focusField: "ad_text",
    },
    {
      index: 3,
      name: "Image",
      focus: "ad_image",
      condition: "",
      focusField: "",
    },
    {
      index: 4,
      name: "URL",
      focus: "ad_url",
      condition: "",
      focusField: "ad_url",
    },
    {
      index: 5,
      name: "Headline",
      focus: "ad_headline",
      condition: "",
      focusField: "ad_headline",
    },
    {
      index: 6,
      name: "Description",
      focus: "ad_description",
      condition: "",
      focusField: "ad_description",
    },
    {
      index: 7,
      name: "Call to Action",
      focus: "ad_cta",
      condition: "",
      focusField: "ad_cta",
    },
  ],
};

const mutations = {
  SET_CRITICAL_MODAL_DATA(store, object) {
    store.criticalModalData = object;
  },
  SET_IS_CRITICAL_MODAL_VISIBLE(store, boolean) {
    store.isCriticalModalVisible = boolean;
  },
  SET_NEW_NOTIFICATION(store, object) {
    store.newNotification = object;
  },
  SET_IS_ACCOUNT_MISSING_VISIBLE(store, boolean) {
    store.isAccountMissingVisible = boolean;
  },
  SET_IS_SHARE_LINK_VISIBLE(store, boolean) {
    store.isShareLinkVisible = boolean;
  },
  SET_IS_CONTACT_VISIBLE(store, isVisible) {
    store.isContactVisible = isVisible;
  },
  SET_IS_FETCHING_NEXT(store, isFetching) {
    store.isFetchingNext = isFetching;
  },
  SET_HELP_VISIBILITY(store, boolean) {
    store.isHelpVisible = boolean;
  },
  SET_PREVIEW_VISIBILITY(store, boolean) {
    store.isPreviewVisible = boolean;
  },
  SET_AD_IS_BOOKING(store, boolean) {
    store.isAdBooking = boolean;
  },
  SET_AD_BOOKED(store, boolean) {
    store.isAdBooked = boolean;
  },
  SET_ADPREVIEW_CHECKLIST(store, object) {
    store.adPreviewChecklist[0].condition = object.description;
    store.adPreviewChecklist[1].condition = object.image_url;
    store.adPreviewChecklist[2].condition = object.url;
    store.adPreviewChecklist[3].condition = object.title;
    store.adPreviewChecklist[4].condition = object.secondary_title;
    store.adPreviewChecklist[5].condition = object.cta;
  },
  SET_DEMO_CHECKLIST(store, object) {
    store.demoChecklist[0].condition = object.description;
    store.demoChecklist[1].condition = object.image_url;
    store.demoChecklist[2].condition = object.url;
    store.demoChecklist[3].condition = object.title;
    store.demoChecklist[4].condition = object.secondary_title;
    store.demoChecklist[5].condition = object.cta;
  },
  SET_ADBLOCK_BANNER(store, boolean) {
    store.isAdBlockBannerOn = boolean;
  },
};

const actions = {};

const getters = {
  adPreviewChecklist: (state) => {
    return state.adPreviewChecklist;
  },
  demoChecklist: (state) => {
    return state.demoChecklist;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
