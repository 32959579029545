import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store/store";

// prettier-ignore
const Audience = () => import(/* webpackChunkName: "Audience" */ "../views/Audience.vue");
// prettier-ignore
const Play = () => import(/* webpackChunkName: "Play" */ "../views/AdDemo.vue");
// prettier-ignore
const Goal = () => import(/* webpackChunkName: "Goal" */ "../views/Goal.vue");
// prettier-ignore
const LogIn = () => import(/* webpackChunkName: "LogIn" */ "../views/sign-in/LogIn.vue");
// prettier-ignore
const ForgotPassword = () => import( /* webpackChunkName: "ForgotPassword" */ "../views/sign-in/ForgotPassword.vue" );
// prettier-ignore
const Welcome = () => import(/* webpackChunkName: "Welcome" */ "../views/Welcome.vue");
// prettier-ignore
const WelcomeContent = () => import( /* webpackChunkName: "WelcomeContent" */ "../views/sign-in/WelcomeContent.vue" );
// prettier-ignore
const SignUp = () => import(/* webpackChunkName: "SignUp" */ "../views/sign-in/SignUp.vue");
// prettier-ignore
const AdPreview = () => import(/* webpackChunkName: "AdPreview" */ "../views/AdPreview.vue");
// prettier-ignore
const Booking = () => import(/* webpackChunkName: "Booking" */ "../views/Booking.vue");
// prettier-ignore
const MyAds = () => import(/* webpackChunkName: "MyAds" */ "../views/MyAds.vue");
// prettier-ignore
const MyAccount = () => import(/* webpackChunkName: "MyAccount" */ "../views/MyAccount.vue");
// prettier-ignore
const ResetPassword = () => import( /* webpackChunkName: "ResetPassword" */ "../views/verification/ResetPassword.vue" );
// prettier-ignore
const UserVerification = () => import( /* webpackChunkName: "UserVerification" */ "../views/verification/UserVerification.vue" );
// prettier-ignore
const EmailChangeVerification = () => import( /* webpackChunkName: "EmailChangeVerification" */ "../views/verification/EmailChangeVerification.vue" );
// prettier-ignore
const Payment = () => import(/* webpackChunkName: "Payment" */ "../views/Payment.vue");

Vue.use(VueRouter);

const routes = [
  {
    path: "/play",
    name: "play",
    component: Play,
    props: true,
    meta: {
      isAdPage: true,
      requiresAuth: false,
      toolbar: false,
      help: true,
      disableHeader: true,
    },
  },
  {
    path: "/payment",
    name: "payment",
    component: Payment,
    props: true,
    meta: { disableHeader: true },
  },
  {
    path: "/ad",
    name: "ad",
    component: AdPreview,
    props: true,
    meta: { isAdPage: true, requiresAuth: true, toolbar: true, help: true },
    beforeEnter: (to, from, next) => {
      store.commit("SWITCH_HINT_HUB", null);
      next();
    },
  },
  {
    path: "/goal",
    name: "goal",
    component: Goal,
    meta: { isAdPage: true, requiresAuth: true },
  },
  {
    path: "/audience",
    name: "audience",
    component: Audience,
    beforeEnter: (to, from, next) => {
      store.dispatch("checkAndUploadAdVideo");
      next();
    },
    meta: { isAdPage: true, requiresAuth: true, toolbar: true, preview: true },
  },
  {
    path: "/welcome",
    component: Welcome,
    meta: { disableHeader: true },
    children: [
      {
        path: "",
        name: "welcome",
        component: WelcomeContent,
        meta: { disableHeader: true },
      },
      {
        path: "log-in",
        name: "log-in",
        alias: ["/login", "/log-in"],
        component: LogIn,
        meta: { disableHeader: true },
      },
      {
        path: "sign-up",
        name: "sign-up",
        alias: ["/signup", "/sign-up"],
        component: SignUp,
        meta: { disableHeader: true },
      },
      {
        path: "forgot",
        name: "forgot",
        alias: ["/forgot"],
        component: ForgotPassword,
        meta: { disableHeader: true },
      },
    ],
  },
  {
    path: "/verification",
    redirect: { name: "welcome" },
    component: Welcome,
    meta: { disableHeader: true },
    children: [
      {
        path: "password-resets",
        name: "password-resets",
        component: ResetPassword,
        meta: { disableHeader: true },
      },
      {
        path: "users",
        name: "user-verification",
        component: UserVerification,
        meta: { disableHeader: true },
      },
      {
        path: "email-changes",
        name: "email-changes",
        component: EmailChangeVerification,
        meta: { disableHeader: true },
      },
    ],
  },
  {
    path: "/booking",
    name: "booking",
    component: Booking,
    meta: { isAdPage: true, requiresAuth: true, toolbar: true },
  },
  {
    path: "/",
    name: "my-ads",
    alias: "/my-ads",
    component: MyAds,
    meta: { requiresAuth: true },
  },
  {
    path: "/my-account",
    name: "my-account",
    component: MyAccount,
    meta: { requiresAuth: true },
  },
  { path: "*", redirect: "/" },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (to.path === "/play") {
    next();
  } else if (to.path !== "/goal") {
    if (to.meta.isAdPage && store.state.adBuilder.selectedGoalIndex == null) {
      next({ path: "/goal" });
    }
  }

  if (from.meta.isAdPage && !to.meta.isAdPage) {
    const isAdBooked = store.state.ui.isAdBooked;
    if (isAdBooked || from.path === "/play") {
      next();
    } else {
      const answer = window.confirm(
        "You have unsaved changes. Are you sure you want to leave the page?"
      );
      if (!answer) {
        next(false);
        return false;
      }
    }
  }
  if (to.path === "/login" || to.path === "/signup") {
    if (store.getters["auth/hasToken"]) {
      if (!store.getters["auth/isAuth"]) {
        await store.dispatch("auth/getUser");
        next({ path: "/dashboard" });
      } else {
        next();
      }
    }
  }

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // check for token
    // if not, redirect to login page.
    if (!store.getters["auth/hasToken"]) {
      next({
        path: "/welcome",
        query: { redirect: to.fullPath },
      });
      return;
    } else {
      // check for user data
      // if not, get user
      if (!store.getters["auth/isAuth"]) {
        await store.dispatch("auth/getUser");
      }
      if (to.meta.isAdPage) {
        store.commit("SET_CURRENT_STEP", to.path);
      }
      next();
    }
  } else {
    next(); // make sure to always call next()!
  }
  // Catch hash mode urls
  if (to.fullPath.startsWith("/#")) {
    next({ path: to.fullPath.replace("/#", "") });
  }
});

router.afterEach(() => {
  if (store.state.auth.serverMessage) {
    store.commit("auth/SET_SERVER_MESSAGE", null);
  }
});

export default router;
