<template>
  <div id="app" :class="$router.currentRoute.name + '-view'">
    <div class="ad-description" ref="ad"></div>
    <ApBannerAlert
      v-if="isAdBlockBannerOn && this.$route.meta.requiresAuth"
      :dismissable="true"
      @dismiss="SET_ADBLOCK_BANNER(false)"
      >Hey! It looks like you're using an ad blocker. Please turn it off and
      refresh your screen for the best experience.
    </ApBannerAlert>
    <ApBannerAlert
      v-if="this.$store.getters.ad_mode === 'edit' && this.$route.meta.isAdPage"
      bg-color="var(--color-secondary)"
      font-color="#fff"
      >Editing ad: {{ $store.state.ad.ad.ad_name }}
      <el-button class="tw-ml-4" size="small" @click="$router.push('/')"
        >EXIT</el-button
      >
    </ApBannerAlert>
    <el-container style="height: 100%">
      <div id="fb-root"></div>
      <div
        class="fb-customerchat"
        attribution="setup_tool"
        page_id="111884090581240"
        greeting_dialog_display="icon"
        logged_in_greeting="Any questions regarding AdPuzl, we’ll get back to you within 24 hours."
        logged_out_greeting="Any questions regarding AdPuzl, we’ll get back to you within 24 hours."
        theme_color="#1abc9c"
        v-if="isProduction"
      ></div>
      <el-alert
        v-show="$store.getters.show_trial_end_warning"
        type="warning"
        effect="dark"
        center
        show-icon
        :closable="false"
      >
        <slot name="title"
          >Trial ended. Please
          <router-link to="/my-account"
            >add payment information</router-link
          ></slot
        >
      </el-alert>
      <el-header
        id="app-header"
        ref="appHeader"
        v-if="!$route.meta.disableHeader"
      >
        <div class="container" style="position: relative">
          <router-link class="app-logo-link" :to="{ name: 'my-ads' }">
            <img class="app-logo" :src="logo_image" />
          </router-link>

          <ApStages header />

          <facebook-login
            v-if="enableFacebookLogin"
            @fb-logged-in="handleFbLogin"
          ></facebook-login>
          <el-button ref="menuButton" class="menu-button" @click="toggleMenu">
            <el-image
              class="menu-button__image"
              :src="$store.state.fb.user_profile_picture"
            >
              <template v-slot:placeholder>
                <div class="menu-button__placeholder"></div>
              </template>
              <template v-slot:error>
                <div class="avatar-placeholder">
                  <font-awesome-icon icon="bars" />
                </div>
              </template>
            </el-image>
            <div
              v-if="isFbConnected"
              class="hidden-md-and-down menu-button__username"
            >
              {{ $store.state.fb.user_full_name }}
            </div>
            <font-awesome-icon icon="caret-down" />
          </el-button>
          <transition name="ap-menu">
            <ApMenu
              ref="apMenu"
              v-show="menuVisible"
              @close-menu="menuVisible = false"
            />
          </transition>
        </div>
      </el-header>

      <main :class="{ 'tw-overflow-auto': $route.meta.disableHeader }">
        <!-- route outlet -->
        <!-- component matched by the route will render here -->
        <keep-alive>
          <router-view
            ref="view"
            :fb="FB"
            :facebook="facebook"
            :deliveryEstimate="deliveryEstimate"
            :ad="ad"
            :adCampaign="adCampaign"
            :adSet="adSet"
            :loading="$store.state.ui.isFetchingNext"
            :goal="selectedGoal"
            :adAccount="adAccount"
            :fbPage="fbPage"
            :adCreative="adCreative"
            :adPreview="adPreview"
            id="page-content"
            @change-step="changeStep"
            @ad-account-missing="
              (boolean) => {
                $store.commit('ui/SET_IS_ACCOUNT_MISSING_VISIBLE', boolean);
              }
            "
          ></router-view>
        </keep-alive>
      </main>
      <TheToolbar
        v-if="$route.meta.isAdPage && $route.meta.toolbar"
        @next-step="handleNextStep"
        @book-now="handleBookNow"
        @prev-step="changeStep"
      />
      <ApDialog
        :visible="$store.state.ui.isContactVisible"
        @close="$store.commit('ui/SET_IS_CONTACT_VISIBLE', false)"
      >
        <ApDemoContact></ApDemoContact>
      </ApDialog>
      <!-- <el-footer class="footer-tos">
    
  </el-footer> -->
    </el-container>
    <ApDialog
      :visible="$store.state.ui.isCriticalModalVisible"
      :close-on-click-modal="false"
      :show-close="false"
      :close-on-press-escape="false"
    >
      <template v-slot:title
        >Oops...{{ $store.state.ui.criticalModalData.title }}</template
      >
      <template v-slot:default>
        {{ $store.state.ui.criticalModalData.body }}
      </template>
      <template v-slot:footer>
        <el-button
          type="info"
          @click="$store.commit('ui/SET_IS_CRITICAL_MODAL_VISIBLE', false)"
          >Ok</el-button
        >
      </template>
    </ApDialog>

    <!-- <ApDialog :visible="isMobile && $route.meta.isAdPage" :show-close="false" :close-on-click-modal="false" :close-on-press-escape="false">
  <template v-slot:title>
    Hey there, <span class="highlight">mobile user</span>.
  </template>
  <template v-slot:default>
    <div class="h4">
      <p >
        We’re excited to bring you a great mobile experience but we are not quite there yet.
      </p>
      <p>Visit <a href="https://app.adpuzl.com">AdPuzl</a> on your desktop computer to get your first ad running in minutes.</p>
    </div>
  </template>
  <template v-slot:footer>
    <router-link :to="{name: 'my-ads'}">
      <el-button type="info">BACK TO DASHBOARD</el-button>
    </router-link>
    <p>
      <em>Questions?</em> <br />
      Contact us
      <a href="mailto:support@adpuzl.com">support@adpuzl.com</a>
    </p>
  </template>
</ApDialog> -->

    <ApDialog
      :visible="$store.state.fb.showFacebookLoginDialog"
      @close="$store.commit('SET_FB_LOGIN_VISIBILITY', false)"
    >
      <template v-slot:title>
        Let’s connect your
        <em class="highlight tw-whitespace-nowrap">Facebook account</em>
      </template>
      <template v-slot:default>
        <p>
          By connecting with Facebook you are able set up your ads directly from
          AdPuzl!
        </p>
        <el-button
          @click="$store.state.fb.scope.login"
          class="facebook-login-btn tw-mx-auto tw-mt-4"
          :loading="$store.state.fb.fbStatus.working"
        >
          <font-awesome-icon :icon="['fab', 'facebook-square']" />
          Connect to Facebook
        </el-button>
      </template>
      <template v-slot:footer>
        <p>
          <em>Having trouble?</em> <br />
          Contact us
          <a href="mailto:support@adpuzl.com">support@adpuzl.com</a>
        </p>
      </template>
    </ApDialog>

    <ApDialog
      :visible="
        $store.state.ui.isAccountMissingVisible &&
        (isMissingAdAccount || isMissingBusinessPage)
      "
      @close="$store.commit('ui/SET_IS_ACCOUNT_MISSING_VISIBLE', false)"
    >
      <template v-slot:title>
        <span>Oops…it looks like you don’t have a </span>
        <em class="highlight" v-if="isMissingAdAccount">
          Facebook Ads Account</em
        >
        <span v-if="isMissingAdAccount && isMissingBusinessPage"> or a </span>
        <em class="highlight" v-if="isMissingBusinessPage">Business Page</em>
      </template>
      <template v-slot:default>
        <p class="h3 tw-my-4">
          Don’t worry, we will help! <br />
          <a
            href="https://youtu.be/Qp_NzTO7A0o?t=68"
            target="_blank"
            rel="noopener noreferrer nofollow"
            >Watch our tutorial video</a
          >.
        </p>
        <div class="tw-my-6 tw-flex tw-flex-wrap tw-justify-center tw-gap-4">
          <a
            href="https://business.facebook.com/"
            target="_blank"
            rel="noopener noreferrer nofollow"
            v-if="isMissingAdAccount"
          >
            <el-button type="info">CREATE YOUR AD ACCOUNT</el-button>
          </a>
          <a
            href="https://business.facebook.com/settings/pages"
            target="_blank"
            rel="noopener noreferrer nofollow"
            v-if="isMissingBusinessPage"
          >
            <el-button type="info">ADD A BUSINESS PAGE</el-button>
          </a>
        </div>
        <p>
          Once you have completed setting up your account, click the refresh
          button.
        </p>
        <el-button class="tw-mt-4" @click="fetchAdAccountsManually"
          ><font-awesome-icon icon="sync" /> REFRESH</el-button
        >
      </template>
      <template v-slot:footer>
        <p>
          <em>Still having trouble?</em> <br />
          Contact us
          <a href="mailto:support@adpuzl.com">support@adpuzl.com</a>
        </p>
      </template>
    </ApDialog>

    <ApDialog :visible.sync="accountDialogVisible" top="8vh" center>
      <template v-slot:title>
        Select Your
        <em class="highlight" @click.shift="handleSelectAccountDone"
          >Ad Account &<br />Social Media Page</em
        >
      </template>
      <p class="tw-mt-0">Woohoo! We’re almost done!</p>

      <el-row v-show="isFbConnected" :gutter="20">
        <el-col :span="24">
          <el-form ref="form" label-width="120px" label-position="top">
            <fieldset>
              <h2>Your Ad Account</h2>
              <div class="fieldset-body">
                <el-select
                  v-model="myfb.adAccountModel"
                  placeholder="select your business"
                  @change="
                    $store.dispatch(
                      'changeAdAccount',
                      JSON.parse(myfb.adAccountModel)
                    );
                    myfb.instagramPageModel = null;
                  "
                >
                  <el-option
                    v-for="adacct in myfb.adaccounts"
                    :label="adacct.name"
                    :value="JSON.stringify(adacct)"
                    :key="adacct.id"
                  ></el-option>
                </el-select>
                <i class="el-icon-loading" v-if="fetchingAccounts"></i>
                <i
                  class="el-icon-refresh"
                  v-else
                  @click="fetchAdAccountsManually"
                ></i>
              </div>
            </fieldset>
            <div v-if="!$store.getters.fundingSourceDetails">
              <el-alert type="warning" :closable="false">
                <span slot="title"
                  >Psst, Facebook wants you to add a valid payment method to run
                  ads on this ad account. Please select a different ad account
                  or click the button below to add the payment method on
                  Facebook.</span
                >
              </el-alert>
              <a
                class="tw-my-4"
                style="display: block"
                href="https://www.facebook.com/ads/manager/account_settings/account_billing"
                target="_blank"
              >
                <el-button type="info"
                  >Add Payment Method on Facebook</el-button
                >
              </a>
            </div>
          </el-form>
        </el-col>
        <el-col :span="24">
          <el-form ref="form" label-width="120px" label-position="top">
            <fieldset>
              <h2>Your Business Page</h2>
              <div class="fieldset-body">
                <el-select
                  v-model="myfb.fbPageIndex"
                  placeholder="select the your page"
                  @change="
                    $store.dispatch('changePageByIndex', myfb.fbPageIndex)
                  "
                >
                  <el-option
                    v-for="(pg, index) in myfb.pages"
                    :label="pg.name"
                    :value="index"
                    :key="pg.id"
                  ></el-option>
                </el-select>
                <i class="el-icon-loading" v-if="fetchingAccounts"></i>
                <i
                  class="el-icon-refresh"
                  v-else
                  @click="fetchAdAccountsManually"
                ></i>
              </div>
            </fieldset>
          </el-form>
        </el-col>
      </el-row>
      <template v-slot:footer>
        <el-button
          v-if="$store.getters.fundingSourceDetails"
          type="info"
          @click="handleSelectAccountDone"
          :disabled="
            !(myfb.adAccountModel && myfb.pageModel) ||
            !$store.getters.fundingSourceDetails
          "
          >CONTINUE</el-button
        >
        <el-button
          v-else
          type="info"
          @click="$store.dispatch('getFundingSourceDetails')"
          @click.shift="handleSelectAccountDone"
          :loading="$store.getters.loadingFundingSource"
          >I have added a payment method on Facebook</el-button
        >
      </template>
    </ApDialog>
  </div>
</template>

<script>
import FacebookLogin from "./FacebookLogin.vue";
import ApDemoContact from "@/components/Demo/ApDemoContact.vue";
// import VFacebookLogin from 'vue-facebook-login-component'
import _ from "lodash";
import ApMenu from "./components/ApMenu.vue";
import ApStages from "./components/ApStages.vue";
import ApDialog from "./components/ApDialog";
import TheToolbar from "./components/TheToolbar";
import ApBannerAlert from "./components/ApBannerAlert.vue";
import { mapGetters, mapState, mapMutations } from "vuex";

// setTimeout(() => {
//   window.fbAsyncInit = function() {
//             FB.init({
//               xfbml            : true,
//               version          : 'v9.0'
//             });
//           };

//           (function(d, s, id) {
//             console.log('fb chat');
//           var js, fjs = d.getElementsByTagName(s)[0];
//           if (d.getElementById(id)) return;
//           js = d.createElement(s); js.id = id;
//           js.src = 'https://connect.facebook.net/en_US/sdk/xfbml.customerchat.js';
//           fjs.parentNode.insertBefore(js, fjs);
//         }(document, 'script', 'facebook-jssdk'));

// }, 2000);

export default {
  name: "app",
  components: {
    FacebookLogin,
    ApMenu,
    ApStages,
    ApDialog,
    TheToolbar,
    ApBannerAlert,
    ApDemoContact,
  },
  created() {
    window.addEventListener("click", this.documentClick);
    window.addEventListener("beforeunload", this.handleRefresh);
  },

  beforeDestroy() {
    window.removeEventListener("click", this.documentClick);
    window.removeEventListener("beforeunload", this.handleRefresh);
  },
  mounted: function () {
    this.$nextTick(function () {
      if (this.isMobile) return;
      // window.onbeforeunload = function(e) {
      //   //e.cancelBubble is supported by IE - this will kill the bubbling process.
      //   e.cancelBubble = true;
      //   e.returnValue = 'You sure you want to leave?'; //This is displayed on the dialog
      //   return 'Dialog text here.';
      // };
      this.$store.dispatch("getTargetingBrowseData");

      this.$router.onReady(() => {
        this.activeIndex = this.$router.currentRoute.path;
      });

      this.goalIndex = this.$store.getters.goalIndex;

      this.$store.subscribe((mutation) => {
        switch (mutation.type) {
          case "UPDATE_FB_PAGE":
            // generate preview
            this.$store.dispatch("generateAdPreview", "MOBILE_FEED_STANDARD");
            // console.log(mutation.type)
            // console.log(mutation.payload)

            break;

          default:
            break;
        }
      });

      if (this.$refs.ad.offsetWidth <= 0) {
        this.$store.commit("ui/SET_ADBLOCK_BANNER", true);
      }
    });
  },
  watch: {
    $route(to) {
      this.activeIndex = to.path;
      this.accountDialogVisible = false;

      if (to.name == "ad") {
        this.myfb.adAccountModel = this.$store.getters.myfb.adAccountModel;
      }
    },
    // '$store.state.ad.adAccount': function(newVal, oldVal) {
    //   console.log('$store.state.ad.adAccount', newVal)
    //   this.myfb.adAccountModel = JSON.stringify(newVal)
    //   console.log(JSON.stringify(newVal))
    // },
    "$store.state.ad.fbPageIndex": function (newVal) {
      this.myfb.fbPageIndex = newVal;
    },
    "$store.state.ui.criticalModalData": {
      handler: function () {
        this.$store.commit("ui/SET_IS_CRITICAL_MODAL_VISIBLE", true);
      },
      deep: true,
    },
    "$store.state.ui.newNotification": function () {
      this.$notify(this.$store.state.ui.newNotification);
    },
  },
  computed: {
    ...mapState("auth", {
      enableFacebookLogin: (state) => state.enableFacebookLogin,
    }),
    ...mapState({
      FB: (state) => state.fb.FB,
      access_token: (state) => state.fb.user.access_token,
      user_id: (state) => state.fb.user_id,
    }),
    ...mapState("ui", {
      isAdBlockBannerOn: (state) => state.isAdBlockBannerOn,
    }),
    ...mapGetters([
      "selectedGoal",
      "isFbConnected",
      "apiTokenParam",
      "isLeadGen",
    ]),

    isMissingAdAccount: function () {
      return (
        this.$store.getters.myfb.adaccounts === null ||
        this.$store.getters.myfb.adaccounts.length === 0
      );
    },

    isMissingBusinessPage: function () {
      return (
        this.$store.getters.myfb.pages === null ||
        this.$store.getters.myfb.pages.length === 0
      );
    },

    isProduction: () => {
      return process.env.NODE_ENV == "production";
    },
    isMobile: function () {
      var check = false;
      (function (a) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
            a
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
            a.substr(0, 4)
          )
        )
          check = true;
      })(navigator.userAgent || navigator.vendor || window.opera);
      return check;
    },
    readyToProceed: function () {
      // Goal step
      switch (this.$route.name) {
        case "goal":
          return this.$store.getters.selectedGoalIndex != null;
        case "ad":
          return true;
        // return !this.$store.getters.hasCleanField
        case "audience":
          return this.$store.getters.adSet.id;
      }
      return true;
    },

    showNextbutton: function () {
      // Goal step

      switch (this.$route.name) {
        case "booking":
          return false;
        case "my-ads":
          return false;
        case "my-account":
          return false;
      }
      return true;
    },

    publisherPlatforms: function () {
      let publisher_platforms = [];

      if (this.ad.placements.includes("instagram")) {
        publisher_platforms.push("instagram");
      }

      if (
        this.ad.placements.includes("facebook_m") ||
        this.ad.placements.includes("facebook_d")
      ) {
        publisher_platforms.push("facebook");
      }

      return publisher_platforms;
    },
    currentStepNumber: function () {
      let path = "/" + this.$route.name;
      return _.findIndex(this.steps, (step) => {
        return step.path == path;
      });
    },
    adAccountHasChanged() {
      return (
        this.$store.getters.adSet.dirty &&
        this.myfb.adAccountModel !== this.selectedAdAccountModel
      );
    },
  },
  data: function () {
    return {
      menuVisible: false,
      logo_image: require("./assets/logo-horizontal.svg"),
      // userJwt: '',
      deliveryEstimate: [],
      fbPage: null,
      business: null,
      adCreative: {
        name: null,
        id: null,
        object_story_spec: null,
      },
      adAccount: {
        id: null,
      },
      adCampaign: {
        id: null,
      },
      adSet: {
        id: null,
        startDateTime: new Date(),
        endDateTime: null,
        campaignKeepRunning: true,
        demographics: [],
        geo_locations: [],
        age_min: 23,
        age_max: 60,
        billing_event: null,
        optimization_goal: null,
        dailyAdBudget: 5,
        adPlacementNames: [],
      },
      ad: {
        id: null,
        placements: [],
        age_min: 22,
        age_max: 30,
        gender: "All",
      },
      adPreview: {
        html: null,
      },
      userProfilePicture: "",
      facebook: {
        model: {},
        scope: null,
        loggedIn: false,
        loginOptions: {
          scope:
            "email, business_management, ads_management, public_profile, pages_show_list",
          return_scopes: true,
          // auth_type: 'rerequest',
          auth_type: "reauthenticate",
          enable_profile_selector: true,
        },
      },
      goalIndex: null,
      activeIndex: "",
      adpreview: "",
      scope: {},
      datetime: null,
      rating1: 4,
      rating2: null,
      form: {
        name: "",
        region: "",
        date1: "",
        date2: "",
        delivery: false,
        type: [],
        resource: "",
        desc: "",
        currentDate: new Date(),
      },
      currentDate: new Date(),
      steps: [
        { path: "/goal" },
        { path: "/ad" },
        // { path: '/login'},
        { path: "/audience" },
        // { path: '/budget'},
        { path: "/booking" },
      ],
      accountDialogVisible: false,
      myfb: {
        businesses: [],
        pages: [],
        adaccounts: [],
        adAccountModel: null,
        pageModel: null,
        fbPageIndex: null,
        instagramPageModel: null,
      },
      selectedAdAccountModel: null,
      fetchCount: 0,
      fetchingAccounts: false,
      isTestEnv:
        process.env.VUE_APP_ADPUZL_API_HOST === "https://testapi.adpuzl.com",
    };
  },

  methods: {
    ...mapMutations("ui", ["SET_ADBLOCK_BANNER"]),

    handleRefresh(e) {
      if (!this.$route.meta.isAdPage || this.$route.name === "goal") return;
      e.preventDefault();
      e.returnValue = "Changes you made may not be saved."; //Message overridden by most browsers;
    },

    async handleFbLogin() {
      if (this.user_id) {
        await Promise.all([
          this.getAdAccounts(this.user_id),
          this.getPages(this.user_id),
        ]);

        if (
          this.$router.currentRoute.name === "goal" &&
          this.$store.getters.selectedGoalIndex !== null
        ) {
          this.changeStep(1);
        }
      }
    },

    toggleMenu() {
      this.menuVisible = !this.menuVisible;
      if (this.menuVisible) {
        this.$refs.apMenu.$el.focus();
      }
    },

    documentClick(e) {
      if (!this.$refs.menuButton && !this.menuVisible) return;
      if (!this.$refs.menuButton.$el.contains(e.target)) {
        this.menuVisible = false;
      }
    },

    // Log the user in
    login() {
      this.$auth.loginWithRedirect();
    },
    // Log the user out
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin,
      });
    },

    debouncedRemoteUpdateCampaign: _.debounce(() => {
      if (
        this.$store.getters.adSet.geo_locations.length == 0 ||
        this.$store.getters.adSet.startDateTime == null
      ) {
        return false;
      } else if (!this.$store.getters.adCampaign.id) {
        this.remoteCreateCampaign();
      } else {
        this.updateCampaign();
      }
    }, 1000),

    updateCampaign() {
      if (this.$store.getters.adSet.id) {
        this.$store.dispatch("remoteUpdateAdSet");
      } else {
        this.$store.dispatch("remoteCreateAdSet");
      }
    },

    remoteUpdateSpecialAd() {
      this.FB.api(
        `/${this.$store.getters.adCampaign.id}`,
        "POST",
        {
          special_ad_categories: this.$store.state.ad.adSet.specialAdCategory,
          ...this.apiTokenParam,
        },
        (response) => {
          if (response.error) {
            this.$store.commit("ui/SET_CRITICAL_MODAL_DATA", {
              body: response.error.error_user_msg,
              title: response.error.error_user_title,
            });
          } else {
            this.updateCampaign();
          }
        }
      );
    },

    remoteCreateCampaign() {
      this.FB.api(
        `/${this.$store.getters.adAccount.id}/campaigns`,
        "POST",
        {
          name: this.$store.getters.adName,
          // objective: "LINK_CLICKS",
          objective: this.isLeadGen ? "LEAD_GENERATION" : "LINK_CLICKS",
          // status: "PAUSED",
          status: "ACTIVE",
          spend_cap: 10000,
          special_ad_categories: this.$store.state.ad.adSet.specialAdCategory,
          ...this.apiTokenParam,
        },
        (response) => {
          if (response.error) {
            this.$store.commit("ui/SET_CRITICAL_MODAL_DATA", {
              body: response.error.error_user_msg,
              title: response.error.error_user_title,
            });
          } else {
            this.$store.dispatch("LOAD_AD_CAMPAIGN", response);
          }
        }
      );
    },

    remotePutAd() {
      if (this.ad.id === null) {
        this.remoteCreateAd();
      }
    },

    remoteCreateAdCreative(callback) {
      let randomNumber = Math.floor(Math.random() * 99) + 1;
      this.adCreative.name = "Ad Creative " + randomNumber;

      let payload = {
        name: this.adCreative.name,
        object_story_spec: this.$store.getters.objectStorySpec,
        ...this.apiTokenParam,
      };

      this.FB.api(
        `/${this.$store.getters.adAccount.id}/adcreatives`,
        "POST",
        payload,
        (response) => {
          this.adCreative.id = response.id;
          this.$store.commit("UPDATE_AD_CREATIVE_ID", response.id);
          callback();
        }
      );
    },

    remoteCreateAd() {
      let randomNumber = Math.floor(Math.random() * 99) + 1;

      // Call `/agencies` API to gain Ad Account access

      this.FB.api(
        `/${this.$store.getters.adAccount.id}/ads`,
        "POST",
        {
          adset_id: this.$store.getters.adSet.id,
          name: "Ad " + randomNumber,
          creative: { creative_id: this.$store.getters.adCreative.id },
          // "status": "PAUSED"
          status: "ACTIVE",
          ...this.apiTokenParam,
        },
        (response) => {
          this.ad.id = response.id;

          this.$store.commit("ui/SET_NEW_NOTIFICATION", {
            title: `AD ${this.ad.id} created`,
            type: "success",
            duration: 10000,
          });
        }
      );
    },
    handleSelectAccountDone() {
      if (this.adAccountHasChanged) {
        this.$store.dispatch("resetCampaignAndAdSetIds");
      }
      this.selectedAdAccountModel = this.myfb.adAccountModel;
      this.accountDialogVisible = false;
      if (this.steps[this.currentStepNumber].path == "/ad") {
        this.$router.push(this.steps[this.currentStepNumber + 1]); // go to next page
      }

      // Re-generate preview
      this.$store.dispatch("generateAdPreview", null);
    },
    startHacking() {
      this.$store.commit("ui/SET_NEW_NOTIFICATION", {
        title: "It works!",
        type: "success",
        message:
          "We've laid the ground work for you. It's time for you to build something epic!",
        duration: 5000,
      });
    },
    onSubmit() {},

    getAdAccounts(userid) {
      return new Promise((resolve, reject) => {
        this.FB.api(
          `/${userid}/adaccounts?fields=account_id,id,name,account_status`,
          this.apiTokenParam,
          (response) => {
            this.myfb.adaccounts = [];
            if (response.error) {
              this.$store.commit("ui/SET_NEW_NOTIFICATION", {
                title: "Something went wrong",
                message: response.error.message,
                type: "warning",
                duration: 5000,
              });
              reject(response.error.message);
              return;
            }

            let sorted_accounts = _.sortBy(response.data, [
              function (o) {
                return o.name;
              },
            ]);

            sorted_accounts.forEach((acct) => {
              this.myfb.adaccounts.push(acct);
            });
            this.$store.commit("UPDATE_MY_FB", this.myfb);
            this.$store.dispatch("changeAdAccount", this.myfb.adaccounts[0]);

            this.myfb.adAccountModel = JSON.stringify(this.myfb.adaccounts[0]);

            resolve();
          }
        );
      });
    },

    getPages(userid) {
      return new Promise((resolve) => {
        this.FB.api(
          `/${userid}/accounts?fields= access_token,category,category_list,name,id,tasks,is_published`,
          this.apiTokenParam,
          (response) => {
            this.myfb.pages = [];
            let sorted_pages = _.sortBy(response.data, [
              function (o) {
                return o.name;
              },
            ]);
            sorted_pages.forEach((page) => {
              this.myfb.pages.push(page);
            });
            this.$store.commit("UPDATE_MY_FB", this.myfb);
            this.$store.dispatch("changePage", this.myfb.pages[0]);
            this.myfb.pageModel = JSON.stringify(this.myfb.pages[0]);
            resolve();
          }
        );
      });
    },

    changeStep(step) {
      if (!this.isFbConnected) {
        this.$store.commit("SET_FB_LOGIN_VISIBILITY", true);
        return;
      }

      if (this.isMissingAdAccount || this.isMissingBusinessPage) {
        this.$store.commit("ui/SET_IS_ACCOUNT_MISSING_VISIBLE", true);
        return;
      }

      let targetStepIndex = this.currentStepNumber + step;

      if (targetStepIndex >= 0 && targetStepIndex < this.steps.length) {
        switch (this.steps[targetStepIndex].path) {
          case "/ad":
            break;
          case "/audience":
            if (step < 0) {
              break;
            }
            if (this.$store.getters.ad_mode == "edit") {
              break;
            } else {
              this.beforeChangingToAudience();
            }
            return true;

          default:
            break;
        }
        this.$router.push(this.steps[targetStepIndex]);
        document.body.scrollTop = document.documentElement.scrollTop = 0;
      }
    },

    fetchAdAccountsManually() {
      this.fetchCount += 1;
      this.fetchingAccounts = true;

      setTimeout(() => {
        this.fetchingAccounts = false;
      }, 1000);
      let userid = this.FB.getUserID();
      if (userid) {
        this.getAdAccounts(userid);

        if (_.size(this.$store.getters.myfb.adaccounts) > 0) {
          this.$store.commit("ui/SET_IS_ACCOUNT_MISSING_VISIBLE", false);
        }

        this.getPages(userid);
        // this.changeStep(1)
      }
    },

    beforeChangingToAudience() {
      // must have more than 0 ad account

      if (this.$store.getters.ad_mode == "edit") {
        this.$store.commit("ui/SET_IS_ACCOUNT_MISSING_VISIBLE", false);
        this.accountDialogVisible = false;
        return true; // skip account selection
      }

      if (_.size(this.$store.getters.myfb.adaccounts) > 0) {
        this.$store.commit("ui/SET_IS_ACCOUNT_MISSING_VISIBLE", false);
        this.accountDialogVisible = true;
        return false;
      } else {
        this.$store.commit("ui/SET_IS_ACCOUNT_MISSING_VISIBLE", true);
        return false;
      }
    },
    handleConfirmDisableAdBlock() {
      document.location.href = "/";
    },

    handleNextStep() {
      this.$refs.view.nextStep();
    },

    handleBookNow() {
      this.$refs.view.bookAd();
    },
  },
};
</script>

<style>
@import url("https://use.typekit.net/xdl4ftm.css");

html {
  /* min-height: 100%; */
  position: relative;
  /* background-color: rgb(236,240,241); */
}

body {
  background-color: rgb(236, 240, 241);
  height: 100vh;
  overflow: hidden;
  /* min-height: 100vh; */
}

body {
  margin: 0;
  background-color: rgb(44, 62, 80);
}

.page-header-helper {
  font-size: 16px;
  font-weight: normal;
  font-family: "dinosaur", sans-serif;
  background: white;
  padding: 10px 30px;
  border-radius: 6px;
  color: rgb(44, 62, 80);
}

.flex-item-align-center {
  display: flex;
  align-items: center;
}

/* .page-header-title  {
    display: flex;
    align-items: center;
} */
.side-panel {
  min-width: 16rem;
}
.page-title {
  /* background-color: rgb(236,240,241); */
  /* color: rgb(44,62,80); */
  color: #e4e7ed;
  /* padding: 2.5rem; */
  font-size: 1.3rem;
  box-shadow: inset -0.05em -0.05em 0.5em 0.05em rgba(44, 62, 80, 0.4);
}
.page-title h1 {
  margin: 0;
}
.my-ads-title {
  margin: 2em 0 1em;
  color: rgb(236, 240, 241);
}
span.highlight,
span.highlight-white,
span.highlight-teal,
span.highlight-red {
  position: relative;
  display: inline-block;
  z-index: 0;
}

span.highlight::before {
  content: "";
  position: absolute;
  background-color: #f9cf36d9;
  top: 50%;
  left: -5px;
  bottom: 0;
  height: 50%;
  z-index: -1;
  width: calc(100% + 10px);
}

#app-header {
  font-family: var(--ff-heading);
}

.el-header .el-steps--simple {
  background: white;
  width: 100%;
}

.el-steps em {
  font-style: normal;
}

.el-step__title.is-process {
  font-weight: 400;
}

.el-main {
  background-color: rgb(44, 62, 80);
  padding: 0;
}

.default-container {
  margin: 0 auto;
  padding: 0 7rem;
}

#app {
  /* max-width: 1200px; */
  margin: 0 auto;
}

.page-footer {
  padding: 10px 0 20px 0;
}
.center {
  text-align: center;
}

.time {
  font-size: 13px;
  color: #999;
}

.bottom {
  margin-top: 13px;
  line-height: 12px;
}

/* .button {
  padding: 0;
  float: right;
} */

.image {
  width: 100%;
  display: block;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both;
}

.fullwidth {
  width: 100%;
}

.fb-login-button {
  display: flex;
  align-items: center;
}

.input-help {
  color: #909399;
  line-height: 1.3rem;
}

.outline {
  border: 1px solid #ebebeb;
  border-radius: 3px;
  transition: 0.2s;
  margin-bottom: 4rem;
  padding: 2rem;
}

.el-step {
  cursor: pointer;
}

.el-step.is-simple:not(:last-of-type) .el-step__title {
  word-break: normal;
}

.menu-button {
  position: relative;
  justify-content: center;
  align-items: center;
  border: 1px solid #e4e4e4;
  padding: 0.3em 0.7em;
  border-radius: 8px;
  color: var(--midnight-blue);
  max-width: 15em;
  white-space: normal;
  text-align: left;
}

.menu-button:hover,
.menu-button:focus {
  background: #fff;
  color: var(--midnight-blue);
  box-shadow: 0 0 0 1px var(--amethyst);
}

.menu-button > span {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.menu-button span > * + * {
  margin-left: 0.5em;
}

.menu-button__image {
  flex-shrink: 0;
}

.menu-button__username {
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0.25em 0;
}

.menu-button__placeholder,
.menu-button img {
  width: 2.75em;
  height: 2.75em;
  border-radius: 50%;
}

.menu-button__placeholder {
  background: var(--silver);
}

.menu-button .avatar-placeholder {
  color: var(--turquoise);
  font-size: 1.4rem;
  padding: 0.25em;
}
.sticky-navitation {
  position: fixed;
  z-index: 99;
  bottom: 0;
  width: 100%;
  background-color: #ecf0f1;
  -webkit-box-shadow: -0.05em 0.05em 0.5em 0.05em rgba(44, 62, 80, 0.4);
  box-shadow: -0.05em 0.05em 0.5em 0.05em rgba(44, 62, 80, 0.4);
}

.footer-tos {
  position: fixed;
  bottom: -12px;
  right: 0;
}

/* .el-button.el-button--primary,
.el-input-group__append .el-button.el-button--primary {
  background-color: rgb(26,188,156);
  border-color:  rgb(26,188,156);
  min-width: 40px;
} */

.el-button.el-button--primary.is-disabled {
  background-color: #a6a9ad;
  border-color: #a6a9ad;
}

.el-input-group__append .el-button.el-button--primary {
  border-width: 2px;
  color: white;
  border-radius: 0 4px 4px 0;
  line-height: 1.2;
}

.flex {
  display: flex;
}

.el-step.is-simple:not(:last-of-type) .el-step__title {
  max-width: 80%;
}

.login_dialog {
  text-align: center;
}

.login_dialog .el-dialog__body {
  /* max-width: 42rem; */
  margin: 0 auto;
  color: rgb(44, 62, 80);
}

.login_dialog h1 {
  font-size: 2rem;
}

.dialog-footer p {
  text-align: center;
  font-size: 14px;
}

.login_dialog .el-dialog__body p {
  font-size: 1.3rem;
  color: rgb(44, 62, 80);
}

.login_dialog .el-dialog {
  border-width: 3px;
  border-style: solid;
  border-color: rgb(142, 68, 173);
  border-radius: 5px 5px 5px 5px;
  background-color: #f3f6f6;
}

.text-body {
  font-size: 18px;
  font-weight: 200;
}

.el-dialog fieldset {
  padding: 0;
  border: 0;
}

fieldset {
  border: none;
  border-radius: 3px;
  transition: 0.2s;
  margin-bottom: 1.5rem;
  padding: 2rem;
  box-shadow: 0.05em 0.05em 0.5em 0.05em rgba(0, 0, 0, 0.2);
}

fieldset h2 {
  font-size: 18px;
  font-weight: bold;
}

fieldset > h2 {
  background-color: rgb(26, 188, 156);
  padding: 7px;
  margin-top: 0;
  margin-bottom: 0;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  color: rgba(0, 0, 0, 0.5);
  /* text-align: left; */
}
.fieldset-body {
  padding: 1rem 2rem;
}

.fieldset-body .el-form-item {
  margin-bottom: 0;
}

.el-dialog--center .el-dialog__body {
  text-align: center;
}

.is-sticky {
  z-index: 99;
}

.el-alert--warning {
  border-radius: 0;
}

.el-alert--warning .el-alert__description {
  font-size: 1rem !important;
}

.el-alert--warning .el-alert__description a {
  color: rgb(155, 89, 182);
}

.hide {
  display: none;
}

.el-dialog__body {
  word-break: break-word;
}

.login_dialog .el-alert__title {
  font-size: 18px;
  line-height: 26px;
}

.el-icon-refresh {
  cursor: pointer;
}
.el-step.is-simple .el-step__icon {
  background: #1abc9c;
  padding: 1.1em;
  border-radius: 50%;
  color: #ffffff;
}
.el-step.is-simple .is-process .el-step__icon {
  background: #9b59b6;
}

.scrollable {
  max-height: calc(100vh - 60px);
  overflow: scroll;
  overflow-x: scroll;
  overflow-y: scroll;
}

.facebook-login-btn {
  cursor: pointer;
  padding: 0.5em 1em;
  text-transform: uppercase;
  color: #fff;
  background-color: #3c57a4;
}

.facebook-login-btn span {
  display: flex;
  align-items: center;
  justify-content: center;
}

.facebook-login-btn svg {
  font-size: 1.5rem;
  margin-right: 0.25em;
}
.facebook-login-btn:hover,
.facebook-login-btn:focus {
  background-color: #425fb3;
  color: #fff;
}

@media only screen and (max-width: 767px) {
  .fb_dialog {
    display: none;
  }
}

@media only screen and (max-width: 991px) {
  .el-step__head {
    display: none;
  }
}

dialog {
  display: none;
}
</style>
